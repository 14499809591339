/*======================
    500 page
=======================*/

.page_500 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_500 .container {
  margin: auto;
  width: 880px;
}

.page_500 img {
  width: 100%;
}

.five_zero_file_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 600px;
  background-position: center;
}

.five_zero_file_bg h1 {
  font-size: 80px;
}

.five_zero_file_bg h3 {
  font-size: 80px;
}

.link_500 {
  color: #fff !important;
  padding: 10px 20px;
  background: #f04d56;
  margin: 20px 0;
  display: inline-block;
  border-radius: 4px;
}

.contant_box_500 {
  margin-top: -50px;
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;