.gpu-quantity-input {
  @apply w-90px h-40px text-right rounded-xl;
  border: 1px solid #707070 !important;
  box-shadow: none !important;

  input {
    text-align: right;
    padding-right: 2px !important;
    @apply text-22px;
  }
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;