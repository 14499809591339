.ant-btn-_707070 {
  color: #fff !important;
  background-color: #707070 !important;
  border-color: #707070 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_707070-disabled,
.ant-btn-_707070.disabled,
.ant-btn-_707070[disabled],
.ant-btn-_707070-disabled:hover,
.ant-btn-_707070.disabled:hover,
.ant-btn-_707070[disabled]:hover,
.ant-btn-_707070-disabled:focus,
.ant-btn-_707070.disabled:focus,
.ant-btn-_707070[disabled]:focus,
.ant-btn-_707070-disabled:active,
.ant-btn-_707070.disabled:active,
.ant-btn-_707070[disabled]:active,
.ant-btn-_707070-disabled.active,
.ant-btn-_707070.disabled.active,
.ant-btn-_707070[disabled].active {
  background-color: #8a8a8a !important;
  border-color: #8a8a8a !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_707070:hover {
  color: #fff !important;
  background-color: #3d3d3d !important;
  border-color: #3d3d3d !important;
}
.ant-btn-_707070:active,
.ant-btn-_707070.active {
  color: #fff !important;
  background-color: #3d3d3d !important;
  border-color: #3d3d3d !important;
}
.ant-btn-background-ghost.ant-btn-_707070 {
  color: #707070 !important;
  background: transparent !important;
  border-color: #707070 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_707070:focus {
  color: #7d7d7d !important;
  background: transparent !important;
  border-color: #7d7d7d !important;
}
.ant-btn-background-ghost.ant-btn-_707070:hover {
  color: #3d3d3d !important;
  background: transparent !important;
  border-color: #3d3d3d !important;
}
.ant-btn-background-ghost.ant-btn-_707070:active,
.ant-btn-background-ghost.ant-btn-_707070.active {
  color: #707070 !important;
  background: transparent !important;
  border-color: #3d3d3d !important;
}
.ant-btn-_BD0034 {
  color: #fff !important;
  background-color: #BD0034 !important;
  border-color: #BD0034 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_BD0034-disabled,
.ant-btn-_BD0034.disabled,
.ant-btn-_BD0034[disabled],
.ant-btn-_BD0034-disabled:hover,
.ant-btn-_BD0034.disabled:hover,
.ant-btn-_BD0034[disabled]:hover,
.ant-btn-_BD0034-disabled:focus,
.ant-btn-_BD0034.disabled:focus,
.ant-btn-_BD0034[disabled]:focus,
.ant-btn-_BD0034-disabled:active,
.ant-btn-_BD0034.disabled:active,
.ant-btn-_BD0034[disabled]:active,
.ant-btn-_BD0034-disabled.active,
.ant-btn-_BD0034.disabled.active,
.ant-btn-_BD0034[disabled].active {
  background-color: #f00042 !important;
  border-color: #f00042 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_BD0034:hover {
  color: #fff !important;
  background-color: #570018 !important;
  border-color: #570018 !important;
}
.ant-btn-_BD0034:active,
.ant-btn-_BD0034.active {
  color: #fff !important;
  background-color: #570018 !important;
  border-color: #570018 !important;
}
.ant-btn-background-ghost.ant-btn-_BD0034 {
  color: #BD0034 !important;
  background: transparent !important;
  border-color: #BD0034 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_BD0034:focus {
  color: #d7003b !important;
  background: transparent !important;
  border-color: #d7003b !important;
}
.ant-btn-background-ghost.ant-btn-_BD0034:hover {
  color: #570018 !important;
  background: transparent !important;
  border-color: #570018 !important;
}
.ant-btn-background-ghost.ant-btn-_BD0034:active,
.ant-btn-background-ghost.ant-btn-_BD0034.active {
  color: #BD0034 !important;
  background: transparent !important;
  border-color: #570018 !important;
}
.ant-btn-_FCC103 {
  color: #fff !important;
  background-color: #FCC103 !important;
  border-color: #FCC103 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FCC103-disabled,
.ant-btn-_FCC103.disabled,
.ant-btn-_FCC103[disabled],
.ant-btn-_FCC103-disabled:hover,
.ant-btn-_FCC103.disabled:hover,
.ant-btn-_FCC103[disabled]:hover,
.ant-btn-_FCC103-disabled:focus,
.ant-btn-_FCC103.disabled:focus,
.ant-btn-_FCC103[disabled]:focus,
.ant-btn-_FCC103-disabled:active,
.ant-btn-_FCC103.disabled:active,
.ant-btn-_FCC103[disabled]:active,
.ant-btn-_FCC103-disabled.active,
.ant-btn-_FCC103.disabled.active,
.ant-btn-_FCC103[disabled].active {
  background-color: #fdcd35 !important;
  border-color: #fdcd35 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_FCC103:hover {
  color: #fff !important;
  background-color: #977402 !important;
  border-color: #977402 !important;
}
.ant-btn-_FCC103:active,
.ant-btn-_FCC103.active {
  color: #fff !important;
  background-color: #977402 !important;
  border-color: #977402 !important;
}
.ant-btn-background-ghost.ant-btn-_FCC103 {
  color: #FCC103 !important;
  background: transparent !important;
  border-color: #FCC103 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_FCC103:focus {
  color: #fcc71c !important;
  background: transparent !important;
  border-color: #fcc71c !important;
}
.ant-btn-background-ghost.ant-btn-_FCC103:hover {
  color: #977402 !important;
  background: transparent !important;
  border-color: #977402 !important;
}
.ant-btn-background-ghost.ant-btn-_FCC103:active,
.ant-btn-background-ghost.ant-btn-_FCC103.active {
  color: #FCC103 !important;
  background: transparent !important;
  border-color: #977402 !important;
}
.ant-btn-_925DD8 {
  color: #fff !important;
  background-color: #925DD8 !important;
  border-color: #925DD8 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_925DD8-disabled,
.ant-btn-_925DD8.disabled,
.ant-btn-_925DD8[disabled],
.ant-btn-_925DD8-disabled:hover,
.ant-btn-_925DD8.disabled:hover,
.ant-btn-_925DD8[disabled]:hover,
.ant-btn-_925DD8-disabled:focus,
.ant-btn-_925DD8.disabled:focus,
.ant-btn-_925DD8[disabled]:focus,
.ant-btn-_925DD8-disabled:active,
.ant-btn-_925DD8.disabled:active,
.ant-btn-_925DD8[disabled]:active,
.ant-btn-_925DD8-disabled.active,
.ant-btn-_925DD8.disabled.active,
.ant-btn-_925DD8[disabled].active {
  background-color: #ae86e2 !important;
  border-color: #ae86e2 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_925DD8:hover {
  color: #fff !important;
  background-color: #5f28a7 !important;
  border-color: #5f28a7 !important;
}
.ant-btn-_925DD8:active,
.ant-btn-_925DD8.active {
  color: #fff !important;
  background-color: #5f28a7 !important;
  border-color: #5f28a7 !important;
}
.ant-btn-background-ghost.ant-btn-_925DD8 {
  color: #925DD8 !important;
  background: transparent !important;
  border-color: #925DD8 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_925DD8:focus {
  color: #a072dd !important;
  background: transparent !important;
  border-color: #a072dd !important;
}
.ant-btn-background-ghost.ant-btn-_925DD8:hover {
  color: #5f28a7 !important;
  background: transparent !important;
  border-color: #5f28a7 !important;
}
.ant-btn-background-ghost.ant-btn-_925DD8:active,
.ant-btn-background-ghost.ant-btn-_925DD8.active {
  color: #925DD8 !important;
  background: transparent !important;
  border-color: #5f28a7 !important;
}
.ant-btn-_EE3554 {
  color: #fff !important;
  background-color: #EE3554 !important;
  border-color: #EE3554 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_EE3554-disabled,
.ant-btn-_EE3554.disabled,
.ant-btn-_EE3554[disabled],
.ant-btn-_EE3554-disabled:hover,
.ant-btn-_EE3554.disabled:hover,
.ant-btn-_EE3554[disabled]:hover,
.ant-btn-_EE3554-disabled:focus,
.ant-btn-_EE3554.disabled:focus,
.ant-btn-_EE3554[disabled]:focus,
.ant-btn-_EE3554-disabled:active,
.ant-btn-_EE3554.disabled:active,
.ant-btn-_EE3554[disabled]:active,
.ant-btn-_EE3554-disabled.active,
.ant-btn-_EE3554.disabled.active,
.ant-btn-_EE3554[disabled].active {
  background-color: #f2647c !important;
  border-color: #f2647c !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_EE3554:hover {
  color: #fff !important;
  background-color: #ae0f29 !important;
  border-color: #ae0f29 !important;
}
.ant-btn-_EE3554:active,
.ant-btn-_EE3554.active {
  color: #fff !important;
  background-color: #ae0f29 !important;
  border-color: #ae0f29 !important;
}
.ant-btn-background-ghost.ant-btn-_EE3554 {
  color: #EE3554 !important;
  background: transparent !important;
  border-color: #EE3554 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_EE3554:focus {
  color: #f04d68 !important;
  background: transparent !important;
  border-color: #f04d68 !important;
}
.ant-btn-background-ghost.ant-btn-_EE3554:hover {
  color: #ae0f29 !important;
  background: transparent !important;
  border-color: #ae0f29 !important;
}
.ant-btn-background-ghost.ant-btn-_EE3554:active,
.ant-btn-background-ghost.ant-btn-_EE3554.active {
  color: #EE3554 !important;
  background: transparent !important;
  border-color: #ae0f29 !important;
}
.ant-btn-_404DFF {
  color: #fff !important;
  background-color: #404DFF !important;
  border-color: #404DFF !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_404DFF-disabled,
.ant-btn-_404DFF.disabled,
.ant-btn-_404DFF[disabled],
.ant-btn-_404DFF-disabled:hover,
.ant-btn-_404DFF.disabled:hover,
.ant-btn-_404DFF[disabled]:hover,
.ant-btn-_404DFF-disabled:focus,
.ant-btn-_404DFF.disabled:focus,
.ant-btn-_404DFF[disabled]:focus,
.ant-btn-_404DFF-disabled:active,
.ant-btn-_404DFF.disabled:active,
.ant-btn-_404DFF[disabled]:active,
.ant-btn-_404DFF-disabled.active,
.ant-btn-_404DFF.disabled.active,
.ant-btn-_404DFF[disabled].active {
  background-color: #737dff !important;
  border-color: #737dff !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_404DFF:hover {
  color: #fff !important;
  background-color: #000fd9 !important;
  border-color: #000fd9 !important;
}
.ant-btn-_404DFF:active,
.ant-btn-_404DFF.active {
  color: #fff !important;
  background-color: #000fd9 !important;
  border-color: #000fd9 !important;
}
.ant-btn-background-ghost.ant-btn-_404DFF {
  color: #404DFF !important;
  background: transparent !important;
  border-color: #404DFF !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_404DFF:focus {
  color: #5a65ff !important;
  background: transparent !important;
  border-color: #5a65ff !important;
}
.ant-btn-background-ghost.ant-btn-_404DFF:hover {
  color: #000fd9 !important;
  background: transparent !important;
  border-color: #000fd9 !important;
}
.ant-btn-background-ghost.ant-btn-_404DFF:active,
.ant-btn-background-ghost.ant-btn-_404DFF.active {
  color: #404DFF !important;
  background: transparent !important;
  border-color: #000fd9 !important;
}
.ant-btn-_FF8EA1 {
  color: #fff !important;
  background-color: #FF8EA1 !important;
  border-color: #FF8EA1 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FF8EA1-disabled,
.ant-btn-_FF8EA1.disabled,
.ant-btn-_FF8EA1[disabled],
.ant-btn-_FF8EA1-disabled:hover,
.ant-btn-_FF8EA1.disabled:hover,
.ant-btn-_FF8EA1[disabled]:hover,
.ant-btn-_FF8EA1-disabled:focus,
.ant-btn-_FF8EA1.disabled:focus,
.ant-btn-_FF8EA1[disabled]:focus,
.ant-btn-_FF8EA1-disabled:active,
.ant-btn-_FF8EA1.disabled:active,
.ant-btn-_FF8EA1[disabled]:active,
.ant-btn-_FF8EA1-disabled.active,
.ant-btn-_FF8EA1.disabled.active,
.ant-btn-_FF8EA1[disabled].active {
  background-color: #ffc1cb !important;
  border-color: #ffc1cb !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_FF8EA1:hover {
  color: #fff !important;
  background-color: #ff284c !important;
  border-color: #ff284c !important;
}
.ant-btn-_FF8EA1:active,
.ant-btn-_FF8EA1.active {
  color: #fff !important;
  background-color: #ff284c !important;
  border-color: #ff284c !important;
}
.ant-btn-background-ghost.ant-btn-_FF8EA1 {
  color: #FF8EA1 !important;
  background: transparent !important;
  border-color: #FF8EA1 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_FF8EA1:focus {
  color: #ffa8b6 !important;
  background: transparent !important;
  border-color: #ffa8b6 !important;
}
.ant-btn-background-ghost.ant-btn-_FF8EA1:hover {
  color: #ff284c !important;
  background: transparent !important;
  border-color: #ff284c !important;
}
.ant-btn-background-ghost.ant-btn-_FF8EA1:active,
.ant-btn-background-ghost.ant-btn-_FF8EA1.active {
  color: #FF8EA1 !important;
  background: transparent !important;
  border-color: #ff284c !important;
}
.ant-btn-_5CA5FF {
  color: #fff !important;
  background-color: #5CA5FF !important;
  border-color: #5CA5FF !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_5CA5FF-disabled,
.ant-btn-_5CA5FF.disabled,
.ant-btn-_5CA5FF[disabled],
.ant-btn-_5CA5FF-disabled:hover,
.ant-btn-_5CA5FF.disabled:hover,
.ant-btn-_5CA5FF[disabled]:hover,
.ant-btn-_5CA5FF-disabled:focus,
.ant-btn-_5CA5FF.disabled:focus,
.ant-btn-_5CA5FF[disabled]:focus,
.ant-btn-_5CA5FF-disabled:active,
.ant-btn-_5CA5FF.disabled:active,
.ant-btn-_5CA5FF[disabled]:active,
.ant-btn-_5CA5FF-disabled.active,
.ant-btn-_5CA5FF.disabled.active,
.ant-btn-_5CA5FF[disabled].active {
  background-color: #8fc1ff !important;
  border-color: #8fc1ff !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_5CA5FF:hover {
  color: #fff !important;
  background-color: #006ef5 !important;
  border-color: #006ef5 !important;
}
.ant-btn-_5CA5FF:active,
.ant-btn-_5CA5FF.active {
  color: #fff !important;
  background-color: #006ef5 !important;
  border-color: #006ef5 !important;
}
.ant-btn-background-ghost.ant-btn-_5CA5FF {
  color: #5CA5FF !important;
  background: transparent !important;
  border-color: #5CA5FF !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_5CA5FF:focus {
  color: #76b3ff !important;
  background: transparent !important;
  border-color: #76b3ff !important;
}
.ant-btn-background-ghost.ant-btn-_5CA5FF:hover {
  color: #006ef5 !important;
  background: transparent !important;
  border-color: #006ef5 !important;
}
.ant-btn-background-ghost.ant-btn-_5CA5FF:active,
.ant-btn-background-ghost.ant-btn-_5CA5FF.active {
  color: #5CA5FF !important;
  background: transparent !important;
  border-color: #006ef5 !important;
}
.ant-btn-_6A5F00 {
  color: #fff !important;
  background-color: #6A5F00 !important;
  border-color: #6A5F00 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_6A5F00-disabled,
.ant-btn-_6A5F00.disabled,
.ant-btn-_6A5F00[disabled],
.ant-btn-_6A5F00-disabled:hover,
.ant-btn-_6A5F00.disabled:hover,
.ant-btn-_6A5F00[disabled]:hover,
.ant-btn-_6A5F00-disabled:focus,
.ant-btn-_6A5F00.disabled:focus,
.ant-btn-_6A5F00[disabled]:focus,
.ant-btn-_6A5F00-disabled:active,
.ant-btn-_6A5F00.disabled:active,
.ant-btn-_6A5F00[disabled]:active,
.ant-btn-_6A5F00-disabled.active,
.ant-btn-_6A5F00.disabled.active,
.ant-btn-_6A5F00[disabled].active {
  background-color: #9d8d00 !important;
  border-color: #9d8d00 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_6A5F00:hover {
  color: #fff !important;
  background-color: #040400 !important;
  border-color: #040400 !important;
}
.ant-btn-_6A5F00:active,
.ant-btn-_6A5F00.active {
  color: #fff !important;
  background-color: #040400 !important;
  border-color: #040400 !important;
}
.ant-btn-background-ghost.ant-btn-_6A5F00 {
  color: #6A5F00 !important;
  background: transparent !important;
  border-color: #6A5F00 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_6A5F00:focus {
  color: #847600 !important;
  background: transparent !important;
  border-color: #847600 !important;
}
.ant-btn-background-ghost.ant-btn-_6A5F00:hover {
  color: #040400 !important;
  background: transparent !important;
  border-color: #040400 !important;
}
.ant-btn-background-ghost.ant-btn-_6A5F00:active,
.ant-btn-background-ghost.ant-btn-_6A5F00.active {
  color: #6A5F00 !important;
  background: transparent !important;
  border-color: #040400 !important;
}
.ant-btn-_008704 {
  color: #fff !important;
  background-color: #008704 !important;
  border-color: #008704 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_008704-disabled,
.ant-btn-_008704.disabled,
.ant-btn-_008704[disabled],
.ant-btn-_008704-disabled:hover,
.ant-btn-_008704.disabled:hover,
.ant-btn-_008704[disabled]:hover,
.ant-btn-_008704-disabled:focus,
.ant-btn-_008704.disabled:focus,
.ant-btn-_008704[disabled]:focus,
.ant-btn-_008704-disabled:active,
.ant-btn-_008704.disabled:active,
.ant-btn-_008704[disabled]:active,
.ant-btn-_008704-disabled.active,
.ant-btn-_008704.disabled.active,
.ant-btn-_008704[disabled].active {
  background-color: #00ba06 !important;
  border-color: #00ba06 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_008704:hover {
  color: #fff !important;
  background-color: #002101 !important;
  border-color: #002101 !important;
}
.ant-btn-_008704:active,
.ant-btn-_008704.active {
  color: #fff !important;
  background-color: #002101 !important;
  border-color: #002101 !important;
}
.ant-btn-background-ghost.ant-btn-_008704 {
  color: #008704 !important;
  background: transparent !important;
  border-color: #008704 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_008704:focus {
  color: #00a105 !important;
  background: transparent !important;
  border-color: #00a105 !important;
}
.ant-btn-background-ghost.ant-btn-_008704:hover {
  color: #002101 !important;
  background: transparent !important;
  border-color: #002101 !important;
}
.ant-btn-background-ghost.ant-btn-_008704:active,
.ant-btn-background-ghost.ant-btn-_008704.active {
  color: #008704 !important;
  background: transparent !important;
  border-color: #002101 !important;
}
.ant-btn-_FF7700 {
  color: #fff !important;
  background-color: #FF7700 !important;
  border-color: #FF7700 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FF7700-disabled,
.ant-btn-_FF7700.disabled,
.ant-btn-_FF7700[disabled],
.ant-btn-_FF7700-disabled:hover,
.ant-btn-_FF7700.disabled:hover,
.ant-btn-_FF7700[disabled]:hover,
.ant-btn-_FF7700-disabled:focus,
.ant-btn-_FF7700.disabled:focus,
.ant-btn-_FF7700[disabled]:focus,
.ant-btn-_FF7700-disabled:active,
.ant-btn-_FF7700.disabled:active,
.ant-btn-_FF7700[disabled]:active,
.ant-btn-_FF7700-disabled.active,
.ant-btn-_FF7700.disabled.active,
.ant-btn-_FF7700[disabled].active {
  background-color: #ff9233 !important;
  border-color: #ff9233 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_FF7700:hover {
  color: #fff !important;
  background-color: #994700 !important;
  border-color: #994700 !important;
}
.ant-btn-_FF7700:active,
.ant-btn-_FF7700.active {
  color: #fff !important;
  background-color: #994700 !important;
  border-color: #994700 !important;
}
.ant-btn-background-ghost.ant-btn-_FF7700 {
  color: #FF7700 !important;
  background: transparent !important;
  border-color: #FF7700 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_FF7700:focus {
  color: #ff851a !important;
  background: transparent !important;
  border-color: #ff851a !important;
}
.ant-btn-background-ghost.ant-btn-_FF7700:hover {
  color: #994700 !important;
  background: transparent !important;
  border-color: #994700 !important;
}
.ant-btn-background-ghost.ant-btn-_FF7700:active,
.ant-btn-background-ghost.ant-btn-_FF7700.active {
  color: #FF7700 !important;
  background: transparent !important;
  border-color: #994700 !important;
}
.ant-btn-_FFDD00 {
  color: #fff !important;
  background-color: #FFDD00 !important;
  border-color: #FFDD00 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FFDD00-disabled,
.ant-btn-_FFDD00.disabled,
.ant-btn-_FFDD00[disabled],
.ant-btn-_FFDD00-disabled:hover,
.ant-btn-_FFDD00.disabled:hover,
.ant-btn-_FFDD00[disabled]:hover,
.ant-btn-_FFDD00-disabled:focus,
.ant-btn-_FFDD00.disabled:focus,
.ant-btn-_FFDD00[disabled]:focus,
.ant-btn-_FFDD00-disabled:active,
.ant-btn-_FFDD00.disabled:active,
.ant-btn-_FFDD00[disabled]:active,
.ant-btn-_FFDD00-disabled.active,
.ant-btn-_FFDD00.disabled.active,
.ant-btn-_FFDD00[disabled].active {
  background-color: #ffe433 !important;
  border-color: #ffe433 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_FFDD00:hover {
  color: #fff !important;
  background-color: #998500 !important;
  border-color: #998500 !important;
}
.ant-btn-_FFDD00:active,
.ant-btn-_FFDD00.active {
  color: #fff !important;
  background-color: #998500 !important;
  border-color: #998500 !important;
}
.ant-btn-background-ghost.ant-btn-_FFDD00 {
  color: #FFDD00 !important;
  background: transparent !important;
  border-color: #FFDD00 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_FFDD00:focus {
  color: #ffe01a !important;
  background: transparent !important;
  border-color: #ffe01a !important;
}
.ant-btn-background-ghost.ant-btn-_FFDD00:hover {
  color: #998500 !important;
  background: transparent !important;
  border-color: #998500 !important;
}
.ant-btn-background-ghost.ant-btn-_FFDD00:active,
.ant-btn-background-ghost.ant-btn-_FFDD00.active {
  color: #FFDD00 !important;
  background: transparent !important;
  border-color: #998500 !important;
}
.ant-btn-_7A4A25 {
  color: #fff !important;
  background-color: #7A4A25 !important;
  border-color: #7A4A25 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_7A4A25-disabled,
.ant-btn-_7A4A25.disabled,
.ant-btn-_7A4A25[disabled],
.ant-btn-_7A4A25-disabled:hover,
.ant-btn-_7A4A25.disabled:hover,
.ant-btn-_7A4A25[disabled]:hover,
.ant-btn-_7A4A25-disabled:focus,
.ant-btn-_7A4A25.disabled:focus,
.ant-btn-_7A4A25[disabled]:focus,
.ant-btn-_7A4A25-disabled:active,
.ant-btn-_7A4A25.disabled:active,
.ant-btn-_7A4A25[disabled]:active,
.ant-btn-_7A4A25-disabled.active,
.ant-btn-_7A4A25.disabled.active,
.ant-btn-_7A4A25[disabled].active {
  background-color: #a16231 !important;
  border-color: #a16231 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_7A4A25:hover {
  color: #fff !important;
  background-color: #2c1b0d !important;
  border-color: #2c1b0d !important;
}
.ant-btn-_7A4A25:active,
.ant-btn-_7A4A25.active {
  color: #fff !important;
  background-color: #2c1b0d !important;
  border-color: #2c1b0d !important;
}
.ant-btn-background-ghost.ant-btn-_7A4A25 {
  color: #7A4A25 !important;
  background: transparent !important;
  border-color: #7A4A25 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_7A4A25:focus {
  color: #8e562b !important;
  background: transparent !important;
  border-color: #8e562b !important;
}
.ant-btn-background-ghost.ant-btn-_7A4A25:hover {
  color: #2c1b0d !important;
  background: transparent !important;
  border-color: #2c1b0d !important;
}
.ant-btn-background-ghost.ant-btn-_7A4A25:active,
.ant-btn-background-ghost.ant-btn-_7A4A25.active {
  color: #7A4A25 !important;
  background: transparent !important;
  border-color: #2c1b0d !important;
}
.ant-btn-_D649C4 {
  color: #fff !important;
  background-color: #D649C4 !important;
  border-color: #D649C4 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_D649C4-disabled,
.ant-btn-_D649C4.disabled,
.ant-btn-_D649C4[disabled],
.ant-btn-_D649C4-disabled:hover,
.ant-btn-_D649C4.disabled:hover,
.ant-btn-_D649C4[disabled]:hover,
.ant-btn-_D649C4-disabled:focus,
.ant-btn-_D649C4.disabled:focus,
.ant-btn-_D649C4[disabled]:focus,
.ant-btn-_D649C4-disabled:active,
.ant-btn-_D649C4.disabled:active,
.ant-btn-_D649C4[disabled]:active,
.ant-btn-_D649C4-disabled.active,
.ant-btn-_D649C4.disabled.active,
.ant-btn-_D649C4[disabled].active {
  background-color: #df73d1 !important;
  border-color: #df73d1 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_D649C4:hover {
  color: #fff !important;
  background-color: #972288 !important;
  border-color: #972288 !important;
}
.ant-btn-_D649C4:active,
.ant-btn-_D649C4.active {
  color: #fff !important;
  background-color: #972288 !important;
  border-color: #972288 !important;
}
.ant-btn-background-ghost.ant-btn-_D649C4 {
  color: #D649C4 !important;
  background: transparent !important;
  border-color: #D649C4 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_D649C4:focus {
  color: #db5ecb !important;
  background: transparent !important;
  border-color: #db5ecb !important;
}
.ant-btn-background-ghost.ant-btn-_D649C4:hover {
  color: #972288 !important;
  background: transparent !important;
  border-color: #972288 !important;
}
.ant-btn-background-ghost.ant-btn-_D649C4:active,
.ant-btn-background-ghost.ant-btn-_D649C4.active {
  color: #D649C4 !important;
  background: transparent !important;
  border-color: #972288 !important;
}
.ant-btn-_989834 {
  color: #fff !important;
  background-color: #989834 !important;
  border-color: #989834 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_989834-disabled,
.ant-btn-_989834.disabled,
.ant-btn-_989834[disabled],
.ant-btn-_989834-disabled:hover,
.ant-btn-_989834.disabled:hover,
.ant-btn-_989834[disabled]:hover,
.ant-btn-_989834-disabled:focus,
.ant-btn-_989834.disabled:focus,
.ant-btn-_989834[disabled]:focus,
.ant-btn-_989834-disabled:active,
.ant-btn-_989834.disabled:active,
.ant-btn-_989834[disabled]:active,
.ant-btn-_989834-disabled.active,
.ant-btn-_989834.disabled.active,
.ant-btn-_989834[disabled].active {
  background-color: #bebe41 !important;
  border-color: #bebe41 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_989834:hover {
  color: #fff !important;
  background-color: #4c4c1a !important;
  border-color: #4c4c1a !important;
}
.ant-btn-_989834:active,
.ant-btn-_989834.active {
  color: #fff !important;
  background-color: #4c4c1a !important;
  border-color: #4c4c1a !important;
}
.ant-btn-background-ghost.ant-btn-_989834 {
  color: #989834 !important;
  background: transparent !important;
  border-color: #989834 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_989834:focus {
  color: #abab3a !important;
  background: transparent !important;
  border-color: #abab3a !important;
}
.ant-btn-background-ghost.ant-btn-_989834:hover {
  color: #4c4c1a !important;
  background: transparent !important;
  border-color: #4c4c1a !important;
}
.ant-btn-background-ghost.ant-btn-_989834:active,
.ant-btn-background-ghost.ant-btn-_989834.active {
  color: #989834 !important;
  background: transparent !important;
  border-color: #4c4c1a !important;
}
.ant-btn-_BE7944 {
  color: #fff !important;
  background-color: #BE7944 !important;
  border-color: #BE7944 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_BE7944-disabled,
.ant-btn-_BE7944.disabled,
.ant-btn-_BE7944[disabled],
.ant-btn-_BE7944-disabled:hover,
.ant-btn-_BE7944.disabled:hover,
.ant-btn-_BE7944[disabled]:hover,
.ant-btn-_BE7944-disabled:focus,
.ant-btn-_BE7944.disabled:focus,
.ant-btn-_BE7944[disabled]:focus,
.ant-btn-_BE7944-disabled:active,
.ant-btn-_BE7944.disabled:active,
.ant-btn-_BE7944[disabled]:active,
.ant-btn-_BE7944-disabled.active,
.ant-btn-_BE7944.disabled.active,
.ant-btn-_BE7944[disabled].active {
  background-color: #cb946a !important;
  border-color: #cb946a !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_BE7944:hover {
  color: #fff !important;
  background-color: #744928 !important;
  border-color: #744928 !important;
}
.ant-btn-_BE7944:active,
.ant-btn-_BE7944.active {
  color: #fff !important;
  background-color: #744928 !important;
  border-color: #744928 !important;
}
.ant-btn-background-ghost.ant-btn-_BE7944 {
  color: #BE7944 !important;
  background: transparent !important;
  border-color: #BE7944 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_BE7944:focus {
  color: #c58757 !important;
  background: transparent !important;
  border-color: #c58757 !important;
}
.ant-btn-background-ghost.ant-btn-_BE7944:hover {
  color: #744928 !important;
  background: transparent !important;
  border-color: #744928 !important;
}
.ant-btn-background-ghost.ant-btn-_BE7944:active,
.ant-btn-background-ghost.ant-btn-_BE7944.active {
  color: #BE7944 !important;
  background: transparent !important;
  border-color: #744928 !important;
}
.ant-btn-_F5F5F5 {
  color: #fff !important;
  background-color: #F5F5F5 !important;
  border-color: #F5F5F5 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_F5F5F5-disabled,
.ant-btn-_F5F5F5.disabled,
.ant-btn-_F5F5F5[disabled],
.ant-btn-_F5F5F5-disabled:hover,
.ant-btn-_F5F5F5.disabled:hover,
.ant-btn-_F5F5F5[disabled]:hover,
.ant-btn-_F5F5F5-disabled:focus,
.ant-btn-_F5F5F5.disabled:focus,
.ant-btn-_F5F5F5[disabled]:focus,
.ant-btn-_F5F5F5-disabled:active,
.ant-btn-_F5F5F5.disabled:active,
.ant-btn-_F5F5F5[disabled]:active,
.ant-btn-_F5F5F5-disabled.active,
.ant-btn-_F5F5F5.disabled.active,
.ant-btn-_F5F5F5[disabled].active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_F5F5F5:hover {
  color: #fff !important;
  background-color: #c2c2c2 !important;
  border-color: #c2c2c2 !important;
}
.ant-btn-_F5F5F5:active,
.ant-btn-_F5F5F5.active {
  color: #fff !important;
  background-color: #c2c2c2 !important;
  border-color: #c2c2c2 !important;
}
.ant-btn-background-ghost.ant-btn-_F5F5F5 {
  color: #F5F5F5 !important;
  background: transparent !important;
  border-color: #F5F5F5 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_F5F5F5:focus {
  color: #ffffff !important;
  background: transparent !important;
  border-color: #ffffff !important;
}
.ant-btn-background-ghost.ant-btn-_F5F5F5:hover {
  color: #c2c2c2 !important;
  background: transparent !important;
  border-color: #c2c2c2 !important;
}
.ant-btn-background-ghost.ant-btn-_F5F5F5:active,
.ant-btn-background-ghost.ant-btn-_F5F5F5.active {
  color: #F5F5F5 !important;
  background: transparent !important;
  border-color: #c2c2c2 !important;
}
.ant-btn-_DDDDDD {
  color: #fff !important;
  background-color: #DDDDDD !important;
  border-color: #DDDDDD !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_DDDDDD-disabled,
.ant-btn-_DDDDDD.disabled,
.ant-btn-_DDDDDD[disabled],
.ant-btn-_DDDDDD-disabled:hover,
.ant-btn-_DDDDDD.disabled:hover,
.ant-btn-_DDDDDD[disabled]:hover,
.ant-btn-_DDDDDD-disabled:focus,
.ant-btn-_DDDDDD.disabled:focus,
.ant-btn-_DDDDDD[disabled]:focus,
.ant-btn-_DDDDDD-disabled:active,
.ant-btn-_DDDDDD.disabled:active,
.ant-btn-_DDDDDD[disabled]:active,
.ant-btn-_DDDDDD-disabled.active,
.ant-btn-_DDDDDD.disabled.active,
.ant-btn-_DDDDDD[disabled].active {
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_DDDDDD:hover {
  color: #fff !important;
  background-color: #aaaaaa !important;
  border-color: #aaaaaa !important;
}
.ant-btn-_DDDDDD:active,
.ant-btn-_DDDDDD.active {
  color: #fff !important;
  background-color: #aaaaaa !important;
  border-color: #aaaaaa !important;
}
.ant-btn-background-ghost.ant-btn-_DDDDDD {
  color: #DDDDDD !important;
  background: transparent !important;
  border-color: #DDDDDD !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_DDDDDD:focus {
  color: #eaeaea !important;
  background: transparent !important;
  border-color: #eaeaea !important;
}
.ant-btn-background-ghost.ant-btn-_DDDDDD:hover {
  color: #aaaaaa !important;
  background: transparent !important;
  border-color: #aaaaaa !important;
}
.ant-btn-background-ghost.ant-btn-_DDDDDD:active,
.ant-btn-background-ghost.ant-btn-_DDDDDD.active {
  color: #DDDDDD !important;
  background: transparent !important;
  border-color: #aaaaaa !important;
}
.ant-btn-_42DABF {
  color: #fff !important;
  background-color: #42DABF !important;
  border-color: #42DABF !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_42DABF-disabled,
.ant-btn-_42DABF.disabled,
.ant-btn-_42DABF[disabled],
.ant-btn-_42DABF-disabled:hover,
.ant-btn-_42DABF.disabled:hover,
.ant-btn-_42DABF[disabled]:hover,
.ant-btn-_42DABF-disabled:focus,
.ant-btn-_42DABF.disabled:focus,
.ant-btn-_42DABF[disabled]:focus,
.ant-btn-_42DABF-disabled:active,
.ant-btn-_42DABF.disabled:active,
.ant-btn-_42DABF[disabled]:active,
.ant-btn-_42DABF-disabled.active,
.ant-btn-_42DABF.disabled.active,
.ant-btn-_42DABF[disabled].active {
  background-color: #6de2cd !important;
  border-color: #6de2cd !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_42DABF:hover {
  color: #fff !important;
  background-color: #1e9882 !important;
  border-color: #1e9882 !important;
}
.ant-btn-_42DABF:active,
.ant-btn-_42DABF.active {
  color: #fff !important;
  background-color: #1e9882 !important;
  border-color: #1e9882 !important;
}
.ant-btn-background-ghost.ant-btn-_42DABF {
  color: #42DABF !important;
  background: transparent !important;
  border-color: #42DABF !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_42DABF:focus {
  color: #57dec6 !important;
  background: transparent !important;
  border-color: #57dec6 !important;
}
.ant-btn-background-ghost.ant-btn-_42DABF:hover {
  color: #1e9882 !important;
  background: transparent !important;
  border-color: #1e9882 !important;
}
.ant-btn-background-ghost.ant-btn-_42DABF:active,
.ant-btn-background-ghost.ant-btn-_42DABF.active {
  color: #42DABF !important;
  background: transparent !important;
  border-color: #1e9882 !important;
}
.ant-btn-_888888 {
  color: #fff !important;
  background-color: #888888 !important;
  border-color: #888888 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_888888-disabled,
.ant-btn-_888888.disabled,
.ant-btn-_888888[disabled],
.ant-btn-_888888-disabled:hover,
.ant-btn-_888888.disabled:hover,
.ant-btn-_888888[disabled]:hover,
.ant-btn-_888888-disabled:focus,
.ant-btn-_888888.disabled:focus,
.ant-btn-_888888[disabled]:focus,
.ant-btn-_888888-disabled:active,
.ant-btn-_888888.disabled:active,
.ant-btn-_888888[disabled]:active,
.ant-btn-_888888-disabled.active,
.ant-btn-_888888.disabled.active,
.ant-btn-_888888[disabled].active {
  background-color: #a2a2a2 !important;
  border-color: #a2a2a2 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_888888:hover {
  color: #fff !important;
  background-color: #555555 !important;
  border-color: #555555 !important;
}
.ant-btn-_888888:active,
.ant-btn-_888888.active {
  color: #fff !important;
  background-color: #555555 !important;
  border-color: #555555 !important;
}
.ant-btn-background-ghost.ant-btn-_888888 {
  color: #888888 !important;
  background: transparent !important;
  border-color: #888888 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_888888:focus {
  color: #959595 !important;
  background: transparent !important;
  border-color: #959595 !important;
}
.ant-btn-background-ghost.ant-btn-_888888:hover {
  color: #555555 !important;
  background: transparent !important;
  border-color: #555555 !important;
}
.ant-btn-background-ghost.ant-btn-_888888:active,
.ant-btn-background-ghost.ant-btn-_888888.active {
  color: #888888 !important;
  background: transparent !important;
  border-color: #555555 !important;
}
.ant-btn-_1C3F49 {
  color: #fff !important;
  background-color: #1C3F49 !important;
  border-color: #1C3F49 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_1C3F49-disabled,
.ant-btn-_1C3F49.disabled,
.ant-btn-_1C3F49[disabled],
.ant-btn-_1C3F49-disabled:hover,
.ant-btn-_1C3F49.disabled:hover,
.ant-btn-_1C3F49[disabled]:hover,
.ant-btn-_1C3F49-disabled:focus,
.ant-btn-_1C3F49.disabled:focus,
.ant-btn-_1C3F49[disabled]:focus,
.ant-btn-_1C3F49-disabled:active,
.ant-btn-_1C3F49.disabled:active,
.ant-btn-_1C3F49[disabled]:active,
.ant-btn-_1C3F49-disabled.active,
.ant-btn-_1C3F49.disabled.active,
.ant-btn-_1C3F49[disabled].active {
  background-color: #2a5f6e !important;
  border-color: #2a5f6e !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_1C3F49:hover {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.ant-btn-_1C3F49:active,
.ant-btn-_1C3F49.active {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.ant-btn-background-ghost.ant-btn-_1C3F49 {
  color: #1C3F49 !important;
  background: transparent !important;
  border-color: #1C3F49 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_1C3F49:focus {
  color: #234f5b !important;
  background: transparent !important;
  border-color: #234f5b !important;
}
.ant-btn-background-ghost.ant-btn-_1C3F49:hover {
  color: #000000 !important;
  background: transparent !important;
  border-color: #000000 !important;
}
.ant-btn-background-ghost.ant-btn-_1C3F49:active,
.ant-btn-background-ghost.ant-btn-_1C3F49.active {
  color: #1C3F49 !important;
  background: transparent !important;
  border-color: #000000 !important;
}
.ant-btn-_0E7E99 {
  color: #fff !important;
  background-color: #0E7E99 !important;
  border-color: #0E7E99 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_0E7E99-disabled,
.ant-btn-_0E7E99.disabled,
.ant-btn-_0E7E99[disabled],
.ant-btn-_0E7E99-disabled:hover,
.ant-btn-_0E7E99.disabled:hover,
.ant-btn-_0E7E99[disabled]:hover,
.ant-btn-_0E7E99-disabled:focus,
.ant-btn-_0E7E99.disabled:focus,
.ant-btn-_0E7E99[disabled]:focus,
.ant-btn-_0E7E99-disabled:active,
.ant-btn-_0E7E99.disabled:active,
.ant-btn-_0E7E99[disabled]:active,
.ant-btn-_0E7E99-disabled.active,
.ant-btn-_0E7E99.disabled.active,
.ant-btn-_0E7E99[disabled].active {
  background-color: #12a4c8 !important;
  border-color: #12a4c8 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_0E7E99:hover {
  color: #fff !important;
  background-color: #05313c !important;
  border-color: #05313c !important;
}
.ant-btn-_0E7E99:active,
.ant-btn-_0E7E99.active {
  color: #fff !important;
  background-color: #05313c !important;
  border-color: #05313c !important;
}
.ant-btn-background-ghost.ant-btn-_0E7E99 {
  color: #0E7E99 !important;
  background: transparent !important;
  border-color: #0E7E99 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_0E7E99:focus {
  color: #1091b0 !important;
  background: transparent !important;
  border-color: #1091b0 !important;
}
.ant-btn-background-ghost.ant-btn-_0E7E99:hover {
  color: #05313c !important;
  background: transparent !important;
  border-color: #05313c !important;
}
.ant-btn-background-ghost.ant-btn-_0E7E99:active,
.ant-btn-background-ghost.ant-btn-_0E7E99.active {
  color: #0E7E99 !important;
  background: transparent !important;
  border-color: #05313c !important;
}
.ant-btn-black {
  color: #fff !important;
  background-color: #000000a6 !important;
  border-color: #000000a6 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-black-disabled,
.ant-btn-black.disabled,
.ant-btn-black[disabled],
.ant-btn-black-disabled:hover,
.ant-btn-black.disabled:hover,
.ant-btn-black[disabled]:hover,
.ant-btn-black-disabled:focus,
.ant-btn-black.disabled:focus,
.ant-btn-black[disabled]:focus,
.ant-btn-black-disabled:active,
.ant-btn-black.disabled:active,
.ant-btn-black[disabled]:active,
.ant-btn-black-disabled.active,
.ant-btn-black.disabled.active,
.ant-btn-black[disabled].active {
  background-color: rgba(26, 26, 26, 0.65098039) !important;
  border-color: rgba(26, 26, 26, 0.65098039) !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-black:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.65098039) !important;
  border-color: rgba(0, 0, 0, 0.65098039) !important;
}
.ant-btn-black:active,
.ant-btn-black.active {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.65098039) !important;
  border-color: rgba(0, 0, 0, 0.65098039) !important;
}
.ant-btn-background-ghost.ant-btn-black {
  color: #000000a6 !important;
  background: transparent !important;
  border-color: #000000a6 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-black:focus {
  color: rgba(13, 13, 13, 0.65098039) !important;
  background: transparent !important;
  border-color: rgba(13, 13, 13, 0.65098039) !important;
}
.ant-btn-background-ghost.ant-btn-black:hover {
  color: rgba(0, 0, 0, 0.65098039) !important;
  background: transparent !important;
  border-color: rgba(0, 0, 0, 0.65098039) !important;
}
.ant-btn-background-ghost.ant-btn-black:active,
.ant-btn-background-ghost.ant-btn-black.active {
  color: #000000a6 !important;
  background: transparent !important;
  border-color: rgba(0, 0, 0, 0.65098039) !important;
}
.ant-btn-blue {
  color: #fff !important;
  background-color: #4285F4 !important;
  border-color: #4285F4 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-blue-disabled,
.ant-btn-blue.disabled,
.ant-btn-blue[disabled],
.ant-btn-blue-disabled:hover,
.ant-btn-blue.disabled:hover,
.ant-btn-blue[disabled]:hover,
.ant-btn-blue-disabled:focus,
.ant-btn-blue.disabled:focus,
.ant-btn-blue[disabled]:focus,
.ant-btn-blue-disabled:active,
.ant-btn-blue.disabled:active,
.ant-btn-blue[disabled]:active,
.ant-btn-blue-disabled.active,
.ant-btn-blue.disabled.active,
.ant-btn-blue[disabled].active {
  background-color: #72a4f7 !important;
  border-color: #72a4f7 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-blue:hover {
  color: #fff !important;
  background-color: #0b51c5 !important;
  border-color: #0b51c5 !important;
}
.ant-btn-blue:active,
.ant-btn-blue.active {
  color: #fff !important;
  background-color: #0b51c5 !important;
  border-color: #0b51c5 !important;
}
.ant-btn-background-ghost.ant-btn-blue {
  color: #4285F4 !important;
  background: transparent !important;
  border-color: #4285F4 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-blue:focus {
  color: #5a95f5 !important;
  background: transparent !important;
  border-color: #5a95f5 !important;
}
.ant-btn-background-ghost.ant-btn-blue:hover {
  color: #0b51c5 !important;
  background: transparent !important;
  border-color: #0b51c5 !important;
}
.ant-btn-background-ghost.ant-btn-blue:active,
.ant-btn-background-ghost.ant-btn-blue.active {
  color: #4285F4 !important;
  background: transparent !important;
  border-color: #0b51c5 !important;
}
.ant-btn-green {
  color: #fff !important;
  background-color: #389E0D !important;
  border-color: #389E0D !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-green-disabled,
.ant-btn-green.disabled,
.ant-btn-green[disabled],
.ant-btn-green-disabled:hover,
.ant-btn-green.disabled:hover,
.ant-btn-green[disabled]:hover,
.ant-btn-green-disabled:focus,
.ant-btn-green.disabled:focus,
.ant-btn-green[disabled]:focus,
.ant-btn-green-disabled:active,
.ant-btn-green.disabled:active,
.ant-btn-green[disabled]:active,
.ant-btn-green-disabled.active,
.ant-btn-green.disabled.active,
.ant-btn-green[disabled].active {
  background-color: #49cd11 !important;
  border-color: #49cd11 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-green:hover {
  color: #fff !important;
  background-color: #174005 !important;
  border-color: #174005 !important;
}
.ant-btn-green:active,
.ant-btn-green.active {
  color: #fff !important;
  background-color: #174005 !important;
  border-color: #174005 !important;
}
.ant-btn-background-ghost.ant-btn-green {
  color: #389E0D !important;
  background: transparent !important;
  border-color: #389E0D !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-green:focus {
  color: #40b60f !important;
  background: transparent !important;
  border-color: #40b60f !important;
}
.ant-btn-background-ghost.ant-btn-green:hover {
  color: #174005 !important;
  background: transparent !important;
  border-color: #174005 !important;
}
.ant-btn-background-ghost.ant-btn-green:active,
.ant-btn-background-ghost.ant-btn-green.active {
  color: #389E0D !important;
  background: transparent !important;
  border-color: #174005 !important;
}
.ant-btn-greenblue {
  color: #fff !important;
  background-color: #42dabf !important;
  border-color: #42dabf !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-greenblue-disabled,
.ant-btn-greenblue.disabled,
.ant-btn-greenblue[disabled],
.ant-btn-greenblue-disabled:hover,
.ant-btn-greenblue.disabled:hover,
.ant-btn-greenblue[disabled]:hover,
.ant-btn-greenblue-disabled:focus,
.ant-btn-greenblue.disabled:focus,
.ant-btn-greenblue[disabled]:focus,
.ant-btn-greenblue-disabled:active,
.ant-btn-greenblue.disabled:active,
.ant-btn-greenblue[disabled]:active,
.ant-btn-greenblue-disabled.active,
.ant-btn-greenblue.disabled.active,
.ant-btn-greenblue[disabled].active {
  background-color: #6de2cd !important;
  border-color: #6de2cd !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-greenblue:hover {
  color: #fff !important;
  background-color: #1e9882 !important;
  border-color: #1e9882 !important;
}
.ant-btn-greenblue:active,
.ant-btn-greenblue.active {
  color: #fff !important;
  background-color: #1e9882 !important;
  border-color: #1e9882 !important;
}
.ant-btn-background-ghost.ant-btn-greenblue {
  color: #42dabf !important;
  background: transparent !important;
  border-color: #42dabf !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-greenblue:focus {
  color: #57dec6 !important;
  background: transparent !important;
  border-color: #57dec6 !important;
}
.ant-btn-background-ghost.ant-btn-greenblue:hover {
  color: #1e9882 !important;
  background: transparent !important;
  border-color: #1e9882 !important;
}
.ant-btn-background-ghost.ant-btn-greenblue:active,
.ant-btn-background-ghost.ant-btn-greenblue.active {
  color: #42dabf !important;
  background: transparent !important;
  border-color: #1e9882 !important;
}
.ant-btn-orange {
  color: #fff !important;
  background-color: #eca52b !important;
  border-color: #eca52b !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-orange-disabled,
.ant-btn-orange.disabled,
.ant-btn-orange[disabled],
.ant-btn-orange-disabled:hover,
.ant-btn-orange.disabled:hover,
.ant-btn-orange[disabled]:hover,
.ant-btn-orange-disabled:focus,
.ant-btn-orange.disabled:focus,
.ant-btn-orange[disabled]:focus,
.ant-btn-orange-disabled:active,
.ant-btn-orange.disabled:active,
.ant-btn-orange[disabled]:active,
.ant-btn-orange-disabled.active,
.ant-btn-orange.disabled.active,
.ant-btn-orange[disabled].active {
  background-color: #f0b95a !important;
  border-color: #f0b95a !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-orange:hover {
  color: #fff !important;
  background-color: #a26c0f !important;
  border-color: #a26c0f !important;
}
.ant-btn-orange:active,
.ant-btn-orange.active {
  color: #fff !important;
  background-color: #a26c0f !important;
  border-color: #a26c0f !important;
}
.ant-btn-background-ghost.ant-btn-orange {
  color: #eca52b !important;
  background: transparent !important;
  border-color: #eca52b !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-orange:focus {
  color: #eeaf42 !important;
  background: transparent !important;
  border-color: #eeaf42 !important;
}
.ant-btn-background-ghost.ant-btn-orange:hover {
  color: #a26c0f !important;
  background: transparent !important;
  border-color: #a26c0f !important;
}
.ant-btn-background-ghost.ant-btn-orange:active,
.ant-btn-background-ghost.ant-btn-orange.active {
  color: #eca52b !important;
  background: transparent !important;
  border-color: #a26c0f !important;
}
.ant-btn-puple {
  color: #fff !important;
  background-color: rgba(240, 77, 86, 0.651) !important;
  border-color: rgba(240, 77, 86, 0.651) !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-puple-disabled,
.ant-btn-puple.disabled,
.ant-btn-puple[disabled],
.ant-btn-puple-disabled:hover,
.ant-btn-puple.disabled:hover,
.ant-btn-puple[disabled]:hover,
.ant-btn-puple-disabled:focus,
.ant-btn-puple.disabled:focus,
.ant-btn-puple[disabled]:focus,
.ant-btn-puple-disabled:active,
.ant-btn-puple.disabled:active,
.ant-btn-puple[disabled]:active,
.ant-btn-puple-disabled.active,
.ant-btn-puple.disabled.active,
.ant-btn-puple[disabled].active {
  background-color: rgba(244, 124, 131, 0.651) !important;
  border-color: rgba(244, 124, 131, 0.651) !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-puple:hover {
  color: #fff !important;
  background-color: rgba(198, 17, 27, 0.651) !important;
  border-color: rgba(198, 17, 27, 0.651) !important;
}
.ant-btn-puple:active,
.ant-btn-puple.active {
  color: #fff !important;
  background-color: rgba(198, 17, 27, 0.651) !important;
  border-color: rgba(198, 17, 27, 0.651) !important;
}
.ant-btn-background-ghost.ant-btn-puple {
  color: rgba(240, 77, 86, 0.651) !important;
  background: transparent !important;
  border-color: rgba(240, 77, 86, 0.651) !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-puple:focus {
  color: rgba(242, 101, 108, 0.651) !important;
  background: transparent !important;
  border-color: rgba(242, 101, 108, 0.651) !important;
}
.ant-btn-background-ghost.ant-btn-puple:hover {
  color: rgba(198, 17, 27, 0.651) !important;
  background: transparent !important;
  border-color: rgba(198, 17, 27, 0.651) !important;
}
.ant-btn-background-ghost.ant-btn-puple:active,
.ant-btn-background-ghost.ant-btn-puple.active {
  color: rgba(240, 77, 86, 0.651) !important;
  background: transparent !important;
  border-color: rgba(198, 17, 27, 0.651) !important;
}
.ant-btn-blue_8 {
  color: #fff !important;
  background-color: rgba(66, 133, 244, 0.08) !important;
  border-color: rgba(66, 133, 244, 0.08) !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-blue_8-disabled,
.ant-btn-blue_8.disabled,
.ant-btn-blue_8[disabled],
.ant-btn-blue_8-disabled:hover,
.ant-btn-blue_8.disabled:hover,
.ant-btn-blue_8[disabled]:hover,
.ant-btn-blue_8-disabled:focus,
.ant-btn-blue_8.disabled:focus,
.ant-btn-blue_8[disabled]:focus,
.ant-btn-blue_8-disabled:active,
.ant-btn-blue_8.disabled:active,
.ant-btn-blue_8[disabled]:active,
.ant-btn-blue_8-disabled.active,
.ant-btn-blue_8.disabled.active,
.ant-btn-blue_8[disabled].active {
  background-color: rgba(114, 164, 247, 0.08) !important;
  border-color: rgba(114, 164, 247, 0.08) !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-blue_8:hover {
  color: #fff !important;
  background-color: rgba(11, 81, 197, 0.08) !important;
  border-color: rgba(11, 81, 197, 0.08) !important;
}
.ant-btn-blue_8:active,
.ant-btn-blue_8.active {
  color: #fff !important;
  background-color: rgba(11, 81, 197, 0.08) !important;
  border-color: rgba(11, 81, 197, 0.08) !important;
}
.ant-btn-background-ghost.ant-btn-blue_8 {
  color: rgba(66, 133, 244, 0.08) !important;
  background: transparent !important;
  border-color: rgba(66, 133, 244, 0.08) !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-blue_8:focus {
  color: rgba(90, 149, 245, 0.08) !important;
  background: transparent !important;
  border-color: rgba(90, 149, 245, 0.08) !important;
}
.ant-btn-background-ghost.ant-btn-blue_8:hover {
  color: rgba(11, 81, 197, 0.08) !important;
  background: transparent !important;
  border-color: rgba(11, 81, 197, 0.08) !important;
}
.ant-btn-background-ghost.ant-btn-blue_8:active,
.ant-btn-background-ghost.ant-btn-blue_8.active {
  color: rgba(66, 133, 244, 0.08) !important;
  background: transparent !important;
  border-color: rgba(11, 81, 197, 0.08) !important;
}
.ant-btn-_FFFFFF {
  color: black !important;
  background-color: white !important;
  border-color: #888888 !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FFFFFF:hover {
  color: white !important;
  background-color: #0E7E99 !important;
  border-color: #0E7E99 !important;
}
.ant-btn-_FFFFFF-disabled,
.ant-btn-_FFFFFF.disabled,
.ant-btn-_FFFFFF[disabled],
.ant-btn-_FFFFFF-disabled:hover,
.ant-btn-_FFFFFF.disabled:hover,
.ant-btn-_FFFFFF[disabled]:hover,
.ant-btn-_FFFFFF-disabled:focus,
.ant-btn-_FFFFFF.disabled:focus,
.ant-btn-_FFFFFF[disabled]:focus,
.ant-btn-_FFFFFF-disabled:active,
.ant-btn-_FFFFFF.disabled:active,
.ant-btn-_FFFFFF[disabled]:active,
.ant-btn-_FFFFFF-disabled.active,
.ant-btn-_FFFFFF.disabled.active,
.ant-btn-_FFFFFF[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_FFFFFF:active,
.ant-btn-_FFFFFF.active {
  color: white !important;
  background-color: #0E7E99 !important;
  border-color: #0E7E99 !important;
}
.ant-btn-_EEEEEE {
  color: black !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_EEEEEE-disabled,
.ant-btn-_EEEEEE.disabled,
.ant-btn-_EEEEEE[disabled],
.ant-btn-_EEEEEE-disabled:hover,
.ant-btn-_EEEEEE.disabled:hover,
.ant-btn-_EEEEEE[disabled]:hover,
.ant-btn-_EEEEEE-disabled:focus,
.ant-btn-_EEEEEE.disabled:focus,
.ant-btn-_EEEEEE[disabled]:focus,
.ant-btn-_EEEEEE-disabled:active,
.ant-btn-_EEEEEE.disabled:active,
.ant-btn-_EEEEEE[disabled]:active,
.ant-btn-_EEEEEE-disabled.active,
.ant-btn-_EEEEEE.disabled.active,
.ant-btn-_EEEEEE[disabled].active {
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_EEEEEE:focus {
  color: black !important;
}
.ant-btn-_EEEEEE:hover {
  background-color: #bbbbbb !important;
}
.ant-btn-_EEEEEE:active,
.ant-btn-_EEEEEE.active {
  color: black !important;
  background-color: #EEEEEE !important;
}
.ant-btn-_EEEEEE_D {
  color: black !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_EEEEEE_D-disabled,
.ant-btn-_EEEEEE_D.disabled,
.ant-btn-_EEEEEE_D[disabled],
.ant-btn-_EEEEEE_D-disabled:hover,
.ant-btn-_EEEEEE_D.disabled:hover,
.ant-btn-_EEEEEE_D[disabled]:hover,
.ant-btn-_EEEEEE_D-disabled:focus,
.ant-btn-_EEEEEE_D.disabled:focus,
.ant-btn-_EEEEEE_D[disabled]:focus,
.ant-btn-_EEEEEE_D-disabled:active,
.ant-btn-_EEEEEE_D.disabled:active,
.ant-btn-_EEEEEE_D[disabled]:active,
.ant-btn-_EEEEEE_D-disabled.active,
.ant-btn-_EEEEEE_D.disabled.active,
.ant-btn-_EEEEEE_D[disabled].active {
  color: black !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_EEEEEE_D:hover {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
}
.ant-btn-_EEEEEE_D:active,
.ant-btn-_EEEEEE_D.active {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
}
.ant-btn {
  text-shadow: none !important;
}
