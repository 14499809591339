.auth-screen {
  background-image: url('Sign In Page Background.png');
  background-size: cover;
  background-position: center;
}
.auth-screen .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-bottom-color: #00beeb;
}
.auth-screen .name-wrapper > input:focus {
  border-bottom-color: #00beeb;
}
.auth-screen .background-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: -50px;
  z-index: 1;
}
@media screen and (min-width: 800px) {
  .auth-screen .background-overlay {
    left: -10px;
  }
  .auth-screen .background-overlay.sign-up-form {
    left: -50px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .background-overlay {
    left: -10px;
  }
  .auth-screen .background-overlay.sign-up-form {
    left: -10px;
  }
}
.auth-screen .auth-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 220px;
  margin-right: 30px;
  margin-left: auto;
  align-items: center;
  padding-top: 83px;
  padding-bottom: 80px;
  min-height: calc(100vh - 57px - 269px);
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container {
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin: 0px;
    padding: 44px 84px 44px 84px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container {
    padding: 160px 130px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container {
    padding: 165px 130px;
  }
}
@media screen and (min-width: 1600px) {
  .auth-screen .auth-container {
    padding: 120px 294px;
  }
}
.auth-screen .auth-container .auth-title {
  font-size: 19px;
  line-height: 22px;
  color: #00beeb;
  font-weight: bold;
  text-align: center;
}
.auth-screen .auth-container .auth-title span {
  color: #303030;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-title {
    margin-right: 83px;
    position: relative;
    top: 40px;
    font-size: 33px;
    line-height: 39px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-title {
    top: -40px;
    margin-right: 43px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-title {
    top: -40px;
    margin-right: 100px;
    font-size: 47px;
    line-height: 55px;
    text-align: left;
  }
}
@media screen and (min-width: 1600px) {
  .auth-screen .auth-container .auth-title {
    top: -10px;
    margin-right: 287px;
    font-size: 60px;
    line-height: 70px;
  }
}
.auth-screen .auth-container .auth-content-wrapper {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-top: 27px;
  padding: 20px 23px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper {
    flex-direction: row;
    width: 281px;
    margin-top: 0px;
    padding: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper {
    width: 440px;
    padding: 44px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper {
    width: 503px;
    padding: 54px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav {
  margin-bottom: 35px;
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav {
    margin-bottom: 80px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  padding: 5px;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 35px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 75px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 85px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 11px;
  line-height: 15px;
  font-weight: normal;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1280) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 28px;
    line-height: 37px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper {
  margin-bottom: 15px;
  font-size: 7px;
  line-height: 9px;
  padding-left: 5px;
  padding-right: 0px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 40px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 7px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix {
    margin-right: 9px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix {
    margin-right: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix {
    margin-right: 22px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix svg,
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-suffix svg {
  width: 6px;
  height: 6px;
  position: relative;
  top: -1px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix svg,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-suffix svg {
    width: 8px;
    height: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix svg,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-suffix svg {
    width: 13px;
    height: 13px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-prefix svg,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input-suffix svg {
    width: 16px;
    height: 16px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input {
  font-size: 7px;
  line-height: 9px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 7px;
  line-height: 9px;
  color: #dddddd;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input::placeholder {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input::placeholder {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .ant-input-affix-wrapper .ant-input::placeholder {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .link {
  display: flex;
  justify-content: flex-end;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .link span {
  display: inline-block;
  border-bottom: 1px solid #00beeb;
  font-size: 4px;
  line-height: 5px;
  font-style: italic;
  color: #00beeb;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .link span {
    font-size: 5px;
    line-height: 6px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .link span {
    font-size: 9px;
    line-height: 11px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .link span {
    font-size: 12px;
    line-height: 16px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper {
  margin: 25px 0px 0px 0px;
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper {
    margin-top: 70px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper {
    margin-top: 80px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button {
  height: 20px;
  width: 76px;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button {
    height: 29px;
    width: 104px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button {
    height: 37px;
    width: 120px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button span {
  font-size: 8px;
  font-weight: normal;
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button span {
    font-size: 9px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form .button-wrapper button span {
    font-size: 14px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-input-affix-wrapper.username {
  margin-bottom: 8px;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-input-affix-wrapper.password {
  margin-bottom: 8px;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input {
  font-size: 7px;
  line-height: 9px;
  margin-bottom: 15px;
  margin-left: 15px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 40px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input::placeholder {
  font-size: 7px;
  line-height: 9px;
  color: #dddddd;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input::placeholder {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input::placeholder {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .name-wrapper > .ant-input::placeholder {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom {
  display: flex;
  margin-bottom: 17px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom {
    margin-bottom: 22px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom {
    margin-bottom: 30px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .left-icon svg {
  width: 8px;
  height: 8px;
  position: relative;
  left: 4px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .left-icon svg {
    width: 10px;
    height: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .left-icon svg {
    width: 13px;
    height: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .left-icon svg {
    width: 15px;
    height: 18px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon {
    width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon {
    width: 31px;
    height: 31px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon {
    width: 35px;
    height: 35px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon svg {
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon svg {
    width: 12px;
    height: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon svg {
    width: 19px;
    height: 19px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .right-icon svg {
    width: 23px;
    height: 23px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper {
  flex-grow: 1;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select {
  width: 149px;
  font-size: 7px;
  line-height: 9px;
  height: 16px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select {
    width: 189px;
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select {
    width: 306px;
    font-size: 17px;
    line-height: 23px;
    height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select {
    width: 343px;
    font-size: 20px;
    line-height: 26px;
    height: 30px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-item {
  height: auto;
  font-size: 7px;
  line-height: 9px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-item {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-item {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-item {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector,
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after {
  padding: 0px 7px;
  height: auto;
  font-size: 7px;
  line-height: 9px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after {
    font-size: 10px;
    line-height: 13px;
    padding: 0px 9px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after {
    font-size: 17px;
    line-height: 23px;
    padding: 0px 15px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after {
    font-size: 20px;
    line-height: 26px;
    padding: 0px 24px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector .ant-select-selection-search,
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after .ant-select-selection-search {
  left: 7px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector .ant-select-selection-search,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after .ant-select-selection-search {
    left: 9px;
  }
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector .ant-select-selection-search,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after .ant-select-selection-search {
    left: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector .ant-select-selection-search,
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selector::after .ant-select-selection-search {
    left: 24px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-search-input {
  height: auto;
  font-size: 7px;
  line-height: 9px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-search-input {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-search-input {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-search-input {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-placeholder {
  font-size: 7px;
  line-height: 9px;
  color: #dddddd;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-placeholder {
    font-size: 10px;
    line-height: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-placeholder {
    font-size: 17px;
    line-height: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .select-custom .selection-wrapper .ant-select .ant-select-selection-placeholder {
    font-size: 20px;
    line-height: 26px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper .ant-checkbox {
  transform: scale(0.6);
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper .ant-checkbox {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper .ant-checkbox {
    transform: scale(1);
    margin-left: 4px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper .ant-checkbox + span {
    padding: 0px 15px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper .ant-checkbox + span {
    padding: 0px 20px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper span {
  font-size: 5px;
  line-height: 7px;
}
@media screen and (min-width: 800px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper span {
    font-size: 7px;
    line-height: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper span {
    font-size: 12px;
    line-height: 17px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper span {
    font-size: 14px;
    line-height: 20px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper a {
  font-weight: 500;
  color: #313131;
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .ant-checkbox-wrapper a:hover {
  color: #313131;
}
@media screen and (min-width: 1024px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .button-wrapper {
    margin-top: 28px;
  }
}
@media screen and (min-width: 1280px) {
  .auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .button-wrapper {
    margin-top: 30px;
    width: 122px;
  }
}
.auth-screen .auth-container .auth-content-wrapper .custom-tabs .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-form.sign-up-form .button-wrapper button:disabled {
  background-color: #AAAAAA !important;
  border-color: #AAAAAA !important;
}
.selection-dropdown.ant-select-dropdown .ant-select-item {
  font-size: 8px;
  line-height: 10px;
  min-height: 14px;
}
@media screen and (min-width: 800px) {
  .selection-dropdown.ant-select-dropdown .ant-select-item {
    font-size: 10px;
    line-height: 13px;
    min-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .selection-dropdown.ant-select-dropdown .ant-select-item {
    font-size: 14px;
    line-height: 18px;
    min-height: 28px;
  }
}
.password-info.ant-tooltip .ant-tooltip-inner {
  background-color: #00beeb !important;
  border-radius: 5px !important;
  padding: 0px 4px 10px 4px !important;
  width: 210px;
}
.password-info.ant-tooltip .ant-tooltip-inner span {
  color: white;
  font-size: 8px;
  line-height: 12px;
}
@media screen and (min-width: 800px) {
  .password-info.ant-tooltip .ant-tooltip-inner {
    border-radius: 5px !important;
    padding: 0px 4px 10px 4px !important;
    width: 210px;
  }
  .password-info.ant-tooltip .ant-tooltip-inner span {
    color: white;
    font-size: 8px;
    line-height: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .password-info.ant-tooltip .ant-tooltip-inner {
    border-radius: 5px !important;
    padding: 2px 6px 10px 6px !important;
    width: 230px;
  }
  .password-info.ant-tooltip .ant-tooltip-inner span {
    color: white;
    font-size: 9px;
    line-height: 12px;
  }
}
@media screen and (min-width: 1280px) {
  .password-info.ant-tooltip .ant-tooltip-inner {
    width: 300px;
    padding: 4px 8px 14px 8px !important;
    border-radius: 7px !important;
  }
  .password-info.ant-tooltip .ant-tooltip-inner span {
    color: white;
    font-size: 12px;
    line-height: 16px;
  }
}
.password-info.ant-tooltip .ant-tooltip-arrow-content {
  background-color: #00beeb !important;
}
.w-auto\!important {
  width: auto !important;
}
.auth-alert-popup {
  margin-left: 40px;
  margin-right: 40px;
  width: auto !important;
}
@media screen and (min-width: 500px) {
  .auth-alert-popup {
    margin-left: auto;
    margin-right: auto;
    width: 374px !important;
  }
}
