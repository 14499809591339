@_00BEEB: #00beeb;

@colorFocus: #0e7e99;
@colorHover: #0e7e99;
@colorActive: #0e7e99;
@colorDisabled: lighten(@_00BEEB, 10%);
.ant-btn-_00BEEB {
  color: #fff !important;
  background-color: @_00BEEB !important;
  border-color: @_00BEEB !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_00BEEB-disabled,
.ant-btn-_00BEEB.disabled,
.ant-btn-_00BEEB[disabled],
.ant-btn-_00BEEB-disabled:hover,
.ant-btn-_00BEEB.disabled:hover,
.ant-btn-_00BEEB[disabled]:hover,
.ant-btn-_00BEEB-disabled:focus,
.ant-btn-_00BEEB.disabled:focus,
.ant-btn-_00BEEB[disabled]:focus,
.ant-btn-_00BEEB-disabled:active,
.ant-btn-_00BEEB.disabled:active,
.ant-btn-_00BEEB[disabled]:active,
.ant-btn-_00BEEB-disabled.active,
.ant-btn-_00BEEB.disabled.active,
.ant-btn-_00BEEB[disabled].active {
  background-color: @colorDisabled !important;
  border-color: @colorDisabled !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-btn-_00BEEB:hover {
  color: #fff !important;
  background-color: @colorHover !important;
  border-color: @colorHover !important;
}
.ant-btn-_00BEEB:active,
.ant-btn-_00BEEB.active {
  color: #fff !important;
  background-color: @_00BEEB !important;
  border-color: @_00BEEB !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB {
  color: @_00BEEB !important;
  background: transparent !important;
  border-color: @_00BEEB !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:focus {
  color: @_00BEEB !important;
  background: transparent !important;
  border-color: @_00BEEB !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:hover {
  color: @colorHover !important;
  background: transparent !important;
  border-color: @colorHover !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:active,
.ant-btn-background-ghost.ant-btn-_00BEEB.active {
  color: @_00BEEB !important;
  background: transparent !important;
  border-color: @colorActive !important;
}


@_000000: #000000;
@_000000colorFocus: #606060;
@_000000colorHover: #606060;
@_000000colorActive: #606060;
.ant-btn-_000000 {
  color: #fff !important;
  background-color: @_000000 !important;
  border-color: @_000000 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-_000000:hover {
  color: #fff !important;
  background-color: @_000000colorHover !important;
  border-color: @_000000colorHover !important;
}
.ant-btn-_000000:active,
.ant-btn-_000000.active {
  color: #fff !important;
  background-color: @_000000colorActive !important;
  border-color: @_000000colorActive !important;
}
.ant-btn-background-ghost.ant-btn-_000000 {
  color: @_000000 !important;
  background: transparent !important;
  border-color: @_000000 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_000000:focus {
  color: @_000000colorFocus !important;
  background: transparent !important;
  border-color: @_000000colorFocus !important;
}
.ant-btn-background-ghost.ant-btn-_000000:hover {
  color: @_000000colorHover !important;
  background: transparent !important;
  border-color: @_000000colorHover !important;
}
.ant-btn-background-ghost.ant-btn-_000000:active,
.ant-btn-background-ghost.ant-btn-_000000.active {
  color: @_000000 !important;
  background: transparent !important;
  border-color: @_000000colorActive !important;
}
@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;