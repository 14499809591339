@font-face {
  font-family: 'Roboto-Medium';
  src: url(./../theme/fonts/Roboto-Medium.otf) format('truetype');
  /* font-weight: 500; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(./../theme/fonts/Roboto-Regular.otf) format('truetype');
  /* font-weight: normal; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url(./../theme/fonts/Roboto-Black.ttf) format('truetype');
  /* font-weight: 900; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(./../theme/fonts/Roboto-Bold.otf) format('truetype');
  /* font-weight: bold; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(./../theme/fonts/Roboto-Light.otf) format('truetype');
  /* font-weight: 300; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(./../theme/fonts/Roboto-Thin.ttf) format('truetype');
  /* font-weight: 100; */
  font-style: normal;
  font-display: swap;
}
