#nprogress .bar {
  background: @primary !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px @primary, 0 0 5px @primary;
}

#nprogress .spinner-icon {
  display: none;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding: 0px;
  margin-right: 8px;
}

.border-none {
  .ant-modal-content {
    border-radius: 0px;
  }
}
.ant-btn-primary:hover, .ant-btn-primary:focus{
  background: #0E7E99;
  border-color: #0E7E99;
}

.ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
  padding: 20px !important;
  padding-bottom: 10px !important;
}

.hover-none {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: white;

    &:hover {
      .ant-select-item-option-content {
        text-decoration: underline;
      }
    }
  }

  .ant-select-item-option-selected {
    background-color: white;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
  }
}

.ant-select-item {
  color: black;
}