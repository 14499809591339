#root {
  min-height: 100%;
  height: auto;
}
html,
body {
  min-height: 100vh;
  height: auto;
  background: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  color: black;
  line-height: 1;
}
#root,
html,
.ant-modal-root {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
h1 {
  @apply text-24px;
}
h2 {
  @apply text-20px;
}
.text-inherit {
  color: inherit;
}
.popover-label {
  max-width: 300px;
}
.text-truncate-1 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-truncate-2 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-truncate-3 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-projects--selected {
  top: 139px;
  position: sticky;
  height: calc(100vh - 139px);
}
.sidebar-projects--selected .sidebar-projects--selected-inner {
  height: calc(100vh - 325px);
}
.input-border-bottom {
  border-radius: unset;
  border: none;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #888;
}
.input-border-bottom:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.input-border {
  padding: 6px 10px;
  border: 1px solid #cccccc;
}
.input-border-707070 {
  padding: 6px 10px;
  border: 1px solid #707070;
}
.develop-option-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #707070;
}
.rounded-none.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0;
}
.text-right .ant-input-number-input {
  text-align: right;
  padding-right: 30px;
}
.label-not-require label::before {
  display: none !important;
}
.note-info {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 8px;
  display: inline-block;
  background-color: #bd0034;
  color: #ffffff;
  font-style: italic;
}
.placeholder-italic::placeholder {
  font-style: italic;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #dddddd;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888888;
}
.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.progress-personal-space .ant-progress-bg {
  height: 19px !important;
}
.progress-personal-space .ant-progress-inner {
  border: 1px solid #DDDDDD;
}
#nprogress .bar {
  background: #00beeb !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px #00beeb, 0 0 5px #00beeb;
}
#nprogress .spinner-icon {
  display: none;
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding: 0px;
  margin-right: 8px;
}
.border-none .ant-modal-content {
  border-radius: 0px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #0E7E99;
  border-color: #0E7E99;
}
.ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
  padding: 20px !important;
  padding-bottom: 10px !important;
}
.hover-none .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: white;
}
.hover-none .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover .ant-select-item-option-content {
  text-decoration: underline;
}
.hover-none .ant-select-item-option-selected {
  background-color: white;
}
.hover-none .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
}
.ant-select-item {
  color: black;
}
#overflow-visible {
  overflow: visible !important;
}
.z-99999 {
  z-index: 99999;
}
