.update-stripe-card-form .ant-input-affix-wrapper {
  margin: 0px;
  padding: 7px 11px !important;
}
.update-stripe-card-form .ant-input-affix-wrapper > input.ant-input {
  padding-left: 8px;
}
.update-stripe-card-form input {
  font-size: 14px;
  line-height: 19px;
}
.update-stripe-card-form input::placeholder {
  color: #dddddd;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.update-stripe-card-form .ant-select-selector {
  padding-left: 6px !important;
  height: 36px !important;
}
.update-stripe-card-form .ant-select-selector input {
  height: 36px !important;
}
.update-stripe-card-form .ant-select-selection-item {
  font-size: 14px;
  line-height: 19px;
}
.update-stripe-card-form .ant-select-selection-placeholder {
  color: #dddddd;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.update-stripe-card-form .ant-select-single .ant-select-selector .ant-select-selection-item,
.update-stripe-card-form .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px !important;
}
