.gpu-quantity-input {
  @apply w-90px h-40px text-right rounded-xl;
  border: 1px solid #707070 !important;
  box-shadow: none !important;
}
.gpu-quantity-input input {
  text-align: right;
  padding-right: 2px !important;
  @apply text-22px;
}
