.modal-custom {
  .ant-modal-content {
    @apply rounded-30px;

    .ant-modal-close-x:focus {
      @apply outline-none;
    }

    .ant-modal-body {
      @apply px-40px pt-70px pb-30px;
    }

    .ant-modal-footer {
      border-color: #cccccc;
      @apply p-0 flex justify-between;

      button {
        @apply m-0 text-16px py-20px w-full font-roboto-regular;

        &:focus {
          @apply outline-none;
        }

        &:not(:last-child) {
          border-right: 1px solid #cccccc;
        }
      }
    }
  }
}

.modal-custom__header-footer {
  .ant-modal-close:focus {
    outline: none;
  }
  .ant-modal-close-x {
    line-height: 1;
    height: auto;
    width: auto;
    padding: 20px;
  }
  .ant-modal-body {
    padding-top: 66px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 16px 0 50px;
    @apply max-w-520px mx-auto;
  }
}

.form__aimodel-info--opacity {
  .bg-disable::after {
    display: none;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.bg-disable {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;