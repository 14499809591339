.progress-notification {
  border-radius: 0px;

  .ant-notification-notice-message {
    margin-bottom: 0px;
    width: 100%;
    padding: 0px;

    .ant-progress {
      line-height: 8px;
      font-size: 8px;
    }

    .ant-progress-inner, .ant-progress-success-bg, .ant-progress-bg {
      border-radius: 0px;
    }
  }
}

.Toastify__toast-container.Toastify__toast-container--bottom-left {
  width: 368px;

  .Toastify__toast.Toastify__toast--default.upload-wrapper {
    padding: 0px;
    position: relative;
    border-radius: 0px;
  
    .Toastify__toast-body {
      padding: 0px;
     
  
      .ant-progress-inner, .ant-progress-success-bg, .ant-progress-bg {
        border-radius: 0px;
      }
    }
  
    .Toastify__close-button {
      position: absolute;
      top: 5px;
      right: 10px;
    
      svg {
        fill: black;
        color: black;
        stroke: black;
      }
    }
  }
}


@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;