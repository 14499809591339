.login-footer h3 {
  font-size: 20px;
}
.login-footer span {
  font-size: 13px;
  margin-bottom: 10px;
}
.login-footer .address {
  font-size: 12px;
  margin-bottom: 5px;
}
.login-footer .email {
  font-size: 14px;
  margin-bottom: 19px;
}
.login-footer a {
  color: black;
  font-size: 12px;
}
.login-footer a:hover {
  text-decoration: underline;
}
.form-custom .ant-input,
.form-custom .ant-input-affix-wrapper,
.form-custom .ant-select-selector {
  border-bottom: 1px solid #aaaaaa;
  color: black;
  font-size: 20px;
  border-radius: 0;
}
.form-custom .ant-input svg,
.form-custom .ant-input-affix-wrapper svg,
.form-custom .ant-select-selector svg {
  fill: #aaaaaa;
}
.form-custom .ant-input-affix-wrapper.selected {
  border-bottom-color: #000000;
}
.form-custom .ant-input-affix-wrapper.selected svg {
  fill: #000000;
}
.form-custom input::placeholder {
  color: #aaaaaa;
  font-size: 20px;
  font-style: italic;
}
.form-custom .ant-input-prefix {
  margin-right: 15px;
  color: #aaaaaa;
}
.form-custom .ant-input-prefix svg {
  fill: #aaaaaa;
}
.form-custom .select-custom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  margin-bottom: 60px;
}
.form-custom .select-custom svg {
  fill: #aaaaaa;
}
.form-custom .select-custom .search-svg {
  background-color: #aaaaaa;
}
.form-custom .select-custom.selected svg {
  fill: #000000;
}
.form-custom .select-custom.selected .search-svg {
  background-color: #000000;
}
.form-custom .select-custom .ant-select-selection-placeholder {
  color: #aaaaaa;
  font-size: 20px;
  font-style: italic;
}
.form-custom .select-custom input::placeholder {
  color: #aaaaaa;
  font-size: 20px;
  font-style: italic;
}
