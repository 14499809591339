.custom-tabs > .ant-tabs {
  .ant-tabs-nav {
    @apply mb-110px;

    &::before {
      @apply hidden;
    }

    .ant-tabs-nav-list > .ant-tabs-tab + .ant-tabs-tab {
      @apply ml-90px;
    }

    .ant-tabs-tab {
      @apply text-26px py-5px font-roboto-regular mx-auto;

      .ant-tabs-tab-btn[aria-selected='false'] {
        color: #dddddd;
        @apply font-roboto-bold;
      }
      .ant-tabs-tab-btn[aria-selected='true'] {
        @apply text-black;
      }
    }
  }

  .ant-tabs-ink-bar {
    height: 3px;
  }
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;