#root {
  min-height: 100%;
  height: auto;
}

#app {
  body {
    // line-height: 1;
  }
}

html,
body {
  min-height: 100vh;
  height: auto;
  background: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  color: black;
  line-height: 1;
}

#root,
html,
.ant-modal-root {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

h1 {
  @apply text-24px;
}

h2 {
  @apply text-20px;
}

.text-inherit {
  color: inherit;
}

.popover-label {
  max-width: 300px;
}

.text-truncate-1 {
  .text-truncate-content(1);
}

.text-truncate-2 {
  .text-truncate-content(2);
}
.text-truncate-3 {
  .text-truncate-content(3);
}

// Sidebar Projects
.sidebar-projects--selected {
  top: 139px;
  position: sticky;
  height: calc(100vh - 139px);
  .sidebar-projects--selected-inner {
    height: calc(100vh - 325px);
  }
}

// Form
.input-border-bottom {
  border-radius: unset;
  border: none;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #888;
  &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.input-border {
  padding: 6px 10px;
  border: 1px solid #cccccc;
}

.input-border-707070 {
  padding: 6px 10px;
  border: 1px solid #707070;
}

.develop-option-form {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #707070;
  }
}

.rounded-none.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0;
}
.text-right .ant-input-number-input {
  text-align: right;
  padding-right: 30px;
}
.label-not-require label::before {
  display: none !important;
}

// Info note
.note-info {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 8px;
  display: inline-block;
  background-color: #bd0034;
  color: #ffffff;
  font-style: italic;
}

.placeholder-italic::placeholder {
  font-style: italic;
}

// Css scrollbar
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888888;
}

.clamp-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.progress-personal-space {
  .ant-progress-bg {
      height: 19px !important;
  }

  .ant-progress-inner {
      border: 1px solid #DDDDDD;
  }
}

@import './variable.less';
@import './override.less';
@import './mixin.less';

#overflow-visible {
  overflow: visible !important;
}

.z-99999 {
  z-index: 99999;
}
@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;