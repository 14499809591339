.modal-register {
  max-width: 372px;
  margin: 50px auto;

  .ant-input {
    font-style: italic;
  }
}

.modal-custom {
  .ant-modal-content {
    border-radius: 30px;
  }

  .ant-modal-close-x {
    width: 63px;
    height: 63px;
    display: flex;

    svg {
      margin: auto;
    }
  }
}

.modal-almost-there {
  width: 842px;
  margin: auto;
  margin-bottom: 15px;

  h4 {
    font-size: 42px;
    margin-bottom: 75px;
    line-height: 55px;
    text-align: center;
    color: #00beeb;
    @apply font-roboto-bold;
  }

  .description {
    font-size: 24px;
    margin-bottom: 70px;
    color: #000000;

    p {
      margin-bottom: 0;
    }

    span {
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
      color: black;
      @apply font-roboto-medium;
    }
  }

  .button {
    text-align: center;
    font-size: 12px;
  }
}

.modal-activate {
  text-align: center;
  padding: 65px;

  h4 {
    color: #707070;
    font-size: 36px;
    margin-bottom: 50px;
  }

  & > span {
    display: block;
    color: #999999;
    font-size: 24px;
    margin-bottom: 55px;
  }
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;