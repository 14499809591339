.update-stripe-card-form {
  .ant-input-affix-wrapper {
    margin: 0px;
    padding: 7px 11px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 8px;
  }

  input {
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      color: #dddddd;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .ant-select-selector {
    padding-left: 6px !important;
    height: 36px !important;

    input {
      height: 36px !important;
    }
  }

  .ant-select-selection-item {
    font-size: 14px;
    line-height: 19px;
  }

  .ant-select-selection-placeholder {
    color: #dddddd;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px !important;
  }
}

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;