.login-footer {
  h3 {
    font-size: 20px;
  }

  span {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .address {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .email {
    font-size: 14px;
    margin-bottom: 19px;
  }

  a {
    color: black;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.form-custom {
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-select-selector {
    border-bottom: 1px solid #aaaaaa;
    color: black;
    font-size: 20px;
    border-radius: 0;

    svg {
      fill: #aaaaaa;
    }
  }

  .ant-input-affix-wrapper.selected {
    border-bottom-color: #000000;
    svg {
      fill: #000000;
    }
  }

  input {
    &::placeholder {
      color: #aaaaaa;
      font-size: 20px;
      font-style: italic;
    }
  }

  .ant-input-prefix {
    margin-right: 15px;
    color: #aaaaaa;

    svg {
      fill: #aaaaaa;
    }
  }

  .select-custom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom-width: 1px;
    border-color: #aaaaaa;
    margin-bottom: 60px;

    svg {
      fill: #aaaaaa;
    }

    .search-svg {
      background-color: #aaaaaa;
    }

    &.selected {
      svg {
        fill: #000000;
      }

      .search-svg {
        background-color: #000000;
      }
    }

    .ant-select-selection-placeholder {
      color: #aaaaaa;
      font-size: 20px;
      font-style: italic;
    }

    input {
      &::placeholder {
        color: #aaaaaa;
        font-size: 20px;
        font-style: italic;
      }
    }
  }
}

// html,
// body {
//   background-color: white;
// }

@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;