.modal-register {
  max-width: 372px;
  margin: 50px auto;
}
.modal-register .ant-input {
  font-style: italic;
}
.modal-custom .ant-modal-content {
  border-radius: 30px;
}
.modal-custom .ant-modal-close-x {
  width: 63px;
  height: 63px;
  display: flex;
}
.modal-custom .ant-modal-close-x svg {
  margin: auto;
}
.modal-almost-there {
  width: 842px;
  margin: auto;
  margin-bottom: 15px;
}
.modal-almost-there h4 {
  font-size: 42px;
  margin-bottom: 75px;
  line-height: 55px;
  text-align: center;
  color: #00beeb;
  @apply font-roboto-bold;
}
.modal-almost-there .description {
  font-size: 24px;
  margin-bottom: 70px;
  color: #000000;
}
.modal-almost-there .description p {
  margin-bottom: 0;
}
.modal-almost-there .description span {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  @apply font-roboto-medium;
}
.modal-almost-there .button {
  text-align: center;
  font-size: 12px;
}
.modal-activate {
  text-align: center;
  padding: 65px;
}
.modal-activate h4 {
  color: #707070;
  font-size: 36px;
  margin-bottom: 50px;
}
.modal-activate > span {
  display: block;
  color: #999999;
  font-size: 24px;
  margin-bottom: 55px;
}
