.ant-btn-_00BEEB {
  color: #fff !important;
  background-color: #00beeb !important;
  border-color: #00beeb !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_00BEEB-disabled,
.ant-btn-_00BEEB.disabled,
.ant-btn-_00BEEB[disabled],
.ant-btn-_00BEEB-disabled:hover,
.ant-btn-_00BEEB.disabled:hover,
.ant-btn-_00BEEB[disabled]:hover,
.ant-btn-_00BEEB-disabled:focus,
.ant-btn-_00BEEB.disabled:focus,
.ant-btn-_00BEEB[disabled]:focus,
.ant-btn-_00BEEB-disabled:active,
.ant-btn-_00BEEB.disabled:active,
.ant-btn-_00BEEB[disabled]:active,
.ant-btn-_00BEEB-disabled.active,
.ant-btn-_00BEEB.disabled.active,
.ant-btn-_00BEEB[disabled].active {
  background-color: #1fd4ff !important;
  border-color: #1fd4ff !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-_00BEEB:hover {
  color: #fff !important;
  background-color: #0e7e99 !important;
  border-color: #0e7e99 !important;
}
.ant-btn-_00BEEB:active,
.ant-btn-_00BEEB.active {
  color: #fff !important;
  background-color: #00beeb !important;
  border-color: #00beeb !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB {
  color: #00beeb !important;
  background: transparent !important;
  border-color: #00beeb !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:focus {
  color: #00beeb !important;
  background: transparent !important;
  border-color: #00beeb !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:hover {
  color: #0e7e99 !important;
  background: transparent !important;
  border-color: #0e7e99 !important;
}
.ant-btn-background-ghost.ant-btn-_00BEEB:active,
.ant-btn-background-ghost.ant-btn-_00BEEB.active {
  color: #00beeb !important;
  background: transparent !important;
  border-color: #0e7e99 !important;
}
.ant-btn-_000000 {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  text-shadow: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_000000:hover {
  color: #fff !important;
  background-color: #606060 !important;
  border-color: #606060 !important;
}
.ant-btn-_000000:active,
.ant-btn-_000000.active {
  color: #fff !important;
  background-color: #606060 !important;
  border-color: #606060 !important;
}
.ant-btn-background-ghost.ant-btn-_000000 {
  color: #000000 !important;
  background: transparent !important;
  border-color: #000000 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-_000000:focus {
  color: #606060 !important;
  background: transparent !important;
  border-color: #606060 !important;
}
.ant-btn-background-ghost.ant-btn-_000000:hover {
  color: #606060 !important;
  background: transparent !important;
  border-color: #606060 !important;
}
.ant-btn-background-ghost.ant-btn-_000000:active,
.ant-btn-background-ghost.ant-btn-_000000.active {
  color: #000000 !important;
  background: transparent !important;
  border-color: #606060 !important;
}
