.drag-upload-wapper > span {
  display: block;
}
.modal-upload .ant-modal-close {
  @apply top-20px right-20px outline-none;
}
.modal-upload .ant-modal-close-x {
  line-height: 24px;
  @apply w-24px h-24px;
}
.modal-upload .ant-modal-content {
  @apply rounded-3xl;
}
.modal-upload .ant-modal-body {
  padding: 50px 0;
}
