.drag-upload-wapper > span {
  display: block;
}
.modal-upload {
  .ant-modal-close {
    @apply top-20px right-20px outline-none;
  }

  .ant-modal-close-x {
    line-height: 24px;
    @apply w-24px h-24px;
  }

  .ant-modal-content {
    @apply rounded-3xl;
  }

  .ant-modal-body {
    padding: 50px 0;
  }  
}
@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;