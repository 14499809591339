.progress-notification {
  border-radius: 0px;
}
.progress-notification .ant-notification-notice-message {
  margin-bottom: 0px;
  width: 100%;
  padding: 0px;
}
.progress-notification .ant-notification-notice-message .ant-progress {
  line-height: 8px;
  font-size: 8px;
}
.progress-notification .ant-notification-notice-message .ant-progress-inner,
.progress-notification .ant-notification-notice-message .ant-progress-success-bg,
.progress-notification .ant-notification-notice-message .ant-progress-bg {
  border-radius: 0px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left {
  width: 368px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper {
  padding: 0px;
  position: relative;
  border-radius: 0px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__toast-body {
  padding: 0px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__toast-body .ant-progress-inner,
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__toast-body .ant-progress-success-bg,
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__toast-body .ant-progress-bg {
  border-radius: 0px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__close-button {
  position: absolute;
  top: 5px;
  right: 10px;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left .Toastify__toast.Toastify__toast--default.upload-wrapper .Toastify__close-button svg {
  fill: black;
  color: black;
  stroke: black;
}
