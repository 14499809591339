/*======================
    500 page
=======================*/
.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}
.page_404 .container {
  margin: auto;
  width: 880px;
}
.page_404 img {
  width: 100%;
}
.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 600px;
  background-position: center;
}
.four_zero_four_bg h1 {
  font-size: 80px;
}
.four_zero_four_bg h3 {
  font-size: 80px;
}
.link_500 {
  color: #fff !important;
  padding: 10px 20px;
  background: #f04d56;
  margin: 20px 0;
  display: inline-block;
  border-radius: 4px;
}
.contant_box_500 {
  margin-top: -50px;
}
