/* JUST Tailwind config, don't edit here! */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  @variants hover, focus-within {
    .filter-none {
      filter: none;
    }
    .filter-grayscale {
      filter: grayscale(100%);
    }
  }
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
  vertical-align: initial;
}

.main-page-container {
  @apply px-40px sm:px-0 max-w-594px md:max-w-856px lg:max-w-948px xl:max-w-1272px m-auto;
}
