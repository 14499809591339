@_707070: #707070;
@_BD0034: #BD0034;
@_FCC103: #FCC103;
@_925DD8: #925DD8;
@_EE3554: #EE3554;
@_404DFF: #404DFF;
@_FF8EA1: #FF8EA1;
@_5CA5FF: #5CA5FF;
@_6A5F00: #6A5F00;
@_008704: #008704;
@_FF7700: #FF7700;
@_FFDD00: #FFDD00;
@_7A4A25: #7A4A25;
@_D649C4: #D649C4;
@_989834: #989834;
@_BE7944: #BE7944;

@_EEEEEE: #EEEEEE;
@_DDDDDD: #DDDDDD;
@_42DABF: #42DABF;
@_888888: #888888;
@_F5F5F5: #F5F5F5;
@_1C3F49: #1C3F49;
@_0E7E99: #0E7E99;
@green-color: #389E0D;
@orange-color: #eca52b;
@blue-color: #4285F4;
@greenblue-color: #42dabf;
@blue-8-color: rgba(66, 133, 244, 0.08);
@black-color: #000000a6;
@puple-color: rgba(240, 77, 86, 0.651);
@buttons: _707070 @_707070,
_BD0034 @_BD0034,
_FCC103 @_FCC103,
_925DD8 @_925DD8,
_EE3554 @_EE3554,
_404DFF @_404DFF,
_FF8EA1 @_FF8EA1,
_5CA5FF @_5CA5FF,
_6A5F00 @_6A5F00,
_008704 @_008704,
_FF7700 @_FF7700,
_FFDD00 @_FFDD00,
_7A4A25 @_7A4A25,
_D649C4 @_D649C4,
_989834 @_989834,
_BE7944 @_BE7944, _F5F5F5 @_F5F5F5, _DDDDDD @_DDDDDD, _42DABF @_42DABF, _888888 @_888888, _1C3F49 @_1C3F49, _0E7E99 @_0E7E99, black @black-color, blue @blue-color, green @green-color, greenblue @greenblue-color, orange @orange-color, puple @puple-color, blue_8 @blue-8-color;
.getButtons(@index:1) when(@index <= length(@buttons)) {
    @name: extract(extract(@buttons, @index),1);
    @color: extract(extract(@buttons, @index),2);
    @colorFocus: lighten(@color, 5%);
    @colorHover: darken(@color, 20%);
    @colorActive: darken(@color, 20%);
    @colorDisabled: lighten(@color, 10%);
    .ant-btn-@{name}{
        color: #fff !important;
        background-color: @color !important;
        border-color: @color !important;
        text-shadow: none;
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-@{name}-disabled,
    .ant-btn-@{name}.disabled,
    .ant-btn-@{name}[disabled],
    .ant-btn-@{name}-disabled:hover,
    .ant-btn-@{name}.disabled:hover,
    .ant-btn-@{name}[disabled]:hover,
    .ant-btn-@{name}-disabled:focus,
    .ant-btn-@{name}.disabled:focus,
    .ant-btn-@{name}[disabled]:focus,
    .ant-btn-@{name}-disabled:active,
    .ant-btn-@{name}.disabled:active,
    .ant-btn-@{name}[disabled]:active,
    .ant-btn-@{name}-disabled.active,
    .ant-btn-@{name}.disabled.active,
    .ant-btn-@{name}[disabled].active {
        background-color: @colorDisabled !important;
        border-color: @colorDisabled !important;
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .ant-btn-@{name}:hover {
        color: #fff !important;
        background-color: @colorHover !important;
        border-color: @colorHover !important;
    }
    .ant-btn-@{name}:active,
    .ant-btn-@{name}.active {
        color: #fff !important;
        background-color: @colorActive !important;
        border-color: @colorActive !important;
    }
    .ant-btn-background-ghost.ant-btn-@{name} {
        color: @color !important;
        background: transparent !important;
        border-color: @color !important;
        text-shadow: none !important;
    }
    .ant-btn-background-ghost.ant-btn-@{name}:focus {
        color: @colorFocus !important;
        background: transparent !important;
        border-color: @colorFocus !important;
    }
    .ant-btn-background-ghost.ant-btn-@{name}:hover{
        color: @colorHover !important;
        background: transparent !important;
        border-color: @colorHover !important;
    }
    .ant-btn-background-ghost.ant-btn-@{name}:active, 
    .ant-btn-background-ghost.ant-btn-@{name}.active {
        color: @color !important;
        background: transparent !important;
        border-color: @colorActive !important;
    }
    .getButtons(@index + 1);
};

.getButtons();

@_FFFFFF: #FFFFFF;
.ant-btn-_FFFFFF{
    color: black !important;
    background-color: white !important;
    border-color: #888888 !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_FFFFFF:hover {
    color: white !important;
    background-color: #0E7E99 !important;
    border-color: #0E7E99 !important;
}

.ant-btn-_FFFFFF-disabled,
.ant-btn-_FFFFFF.disabled,
.ant-btn-_FFFFFF[disabled],
.ant-btn-_FFFFFF-disabled:hover,
.ant-btn-_FFFFFF.disabled:hover,
.ant-btn-_FFFFFF[disabled]:hover,
.ant-btn-_FFFFFF-disabled:focus,
.ant-btn-_FFFFFF.disabled:focus,
.ant-btn-_FFFFFF[disabled]:focus,
.ant-btn-_FFFFFF-disabled:active,
.ant-btn-_FFFFFF.disabled:active,
.ant-btn-_FFFFFF[disabled]:active,
.ant-btn-_FFFFFF-disabled.active,
.ant-btn-_FFFFFF.disabled.active,
.ant-btn-_FFFFFF[disabled].active {
    // text-shadow: none !important;
    // -webkit-box-shadow: none !important;
    // box-shadow: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-_FFFFFF:active,
.ant-btn-_FFFFFF.active {
    color: white !important;
    background-color: #0E7E99 !important;
    border-color: #0E7E99 !important;
}


.ant-btn-_EEEEEE{
    color: black !important;
    background-color: @_EEEEEE !important;
    border-color: @_EEEEEE !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_EEEEEE-disabled,
.ant-btn-_EEEEEE.disabled,
.ant-btn-_EEEEEE[disabled],
.ant-btn-_EEEEEE-disabled:hover,
.ant-btn-_EEEEEE.disabled:hover,
.ant-btn-_EEEEEE[disabled]:hover,
.ant-btn-_EEEEEE-disabled:focus,
.ant-btn-_EEEEEE.disabled:focus,
.ant-btn-_EEEEEE[disabled]:focus,
.ant-btn-_EEEEEE-disabled:active,
.ant-btn-_EEEEEE.disabled:active,
.ant-btn-_EEEEEE[disabled]:active,
.ant-btn-_EEEEEE-disabled.active,
.ant-btn-_EEEEEE.disabled.active,
.ant-btn-_EEEEEE[disabled].active {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-_EEEEEE:focus {
    color: black !important;
}

.ant-btn-_EEEEEE:hover {
    background-color: darken(@_EEEEEE, 20%) !important;
}
.ant-btn-_EEEEEE:active,
.ant-btn-_EEEEEE.active {
    color: black !important;
    background-color: @_EEEEEE !important;
}


.ant-btn-_EEEEEE_D{
    color: black !important;
    background-color: @_EEEEEE !important;
    border-color: @_EEEEEE !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-_EEEEEE_D-disabled,
.ant-btn-_EEEEEE_D.disabled,
.ant-btn-_EEEEEE_D[disabled],
.ant-btn-_EEEEEE_D-disabled:hover,
.ant-btn-_EEEEEE_D.disabled:hover,
.ant-btn-_EEEEEE_D[disabled]:hover,
.ant-btn-_EEEEEE_D-disabled:focus,
.ant-btn-_EEEEEE_D.disabled:focus,
.ant-btn-_EEEEEE_D[disabled]:focus,
.ant-btn-_EEEEEE_D-disabled:active,
.ant-btn-_EEEEEE_D.disabled:active,
.ant-btn-_EEEEEE_D[disabled]:active,
.ant-btn-_EEEEEE_D-disabled.active,
.ant-btn-_EEEEEE_D.disabled.active,
.ant-btn-_EEEEEE_D[disabled].active {
    color: black !important;
    background-color: @_EEEEEE !important;
    border-color: @_EEEEEE !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-_EEEEEE_D:hover {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
}
.ant-btn-_EEEEEE_D:active,
.ant-btn-_EEEEEE_D.active {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
}

.ant-btn {
    text-shadow: none !important;
}
@primary-color: #00beeb;@height-base: 32px;@height-sm: 26px;@height-lg: 40px;@border-radius-base: 4px;@btn-font-weight: 500;