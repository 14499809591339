.custom-tabs > .ant-tabs .ant-tabs-nav {
  @apply mb-110px;
}
.custom-tabs > .ant-tabs .ant-tabs-nav::before {
  @apply hidden;
}
.custom-tabs > .ant-tabs .ant-tabs-nav .ant-tabs-nav-list > .ant-tabs-tab + .ant-tabs-tab {
  @apply ml-90px;
}
.custom-tabs > .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  @apply text-26px py-5px font-roboto-regular mx-auto;
}
.custom-tabs > .ant-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn[aria-selected='false'] {
  color: #dddddd;
  @apply font-roboto-bold;
}
.custom-tabs > .ant-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn[aria-selected='true'] {
  @apply text-black;
}
.custom-tabs > .ant-tabs .ant-tabs-ink-bar {
  height: 3px;
}
